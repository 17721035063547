import { Alert } from '@chakra-ui/alert'
import { Box } from '@chakra-ui/layout'
import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal'
import React from 'react'
interface EmergencyPopupProps{
    handleCloseModal:()=>void
}
const EmergencyStartedPopup:React.FC <EmergencyPopupProps>= ({ handleCloseModal }) => {
  return (
    <>
          <Modal
              size={"xl"}
              blockScrollOnMount={true}
              isOpen={true}
              onClose={handleCloseModal}
              
          >
              <ModalOverlay />

              <ModalContent>
                  <ModalHeader color={"#008401"}>Emergency Started</ModalHeader>
                  <ModalCloseButton />
                  <Box mt={".1rem"} mb="1rem">
                      <Box m={".7rem"}>
                          <Alert
                              mt={".5rem"}
                              color={"black"}
                              fontWeight={"500"}
                              fontSize={"1.2rem"}
                              status="info"
                              variant="left-accent"
                          >
                              {/* Thank you for providing the details.", "We will arrange a
                doctor's consultation and dispatch an ambulance right away.",
                "Please stay calm and patient, the Pococare Heartbeat team is
                here for you. */}
                              We will immediately dispatch an ambulance and set up a doctor’s
                              consultation. Please stay available on Whatsapp for an online
                              video consultation with the doctor. Thank you for reaching out
                              to the Pococare Heartbeat team.
                          </Alert>
                      </Box>
                  </Box>
              </ModalContent>
          </Modal>
    </>
  )
}

export default EmergencyStartedPopup
