import { sendWhatsAppMessage } from "../../../Http"

export const sendWhatsappMessage=async (payload:string)=>{
    try{
        const response = await sendWhatsAppMessage(payload);
        return response;

    }catch(e:any){
        throw e;

    }

}