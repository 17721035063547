import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { agentLoginWrap } from "./Action/AgentLogin";
const AgentLogin = () => {
  const [agentLogin, setAgentLoginData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setAgentLoginData({ ...agentLogin, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (agentLogin.email === "" || agentLogin.password === "") {
      return alert("Please Enter Email && Password")
    }
    try {
      await agentLoginWrap(agentLogin)
      localStorage.setItem("isLogin", "true");
      setAgentLoginData({
        email: "",
        password: "",
      });
      return navigate("/ticket");

    } catch (e: any) {
      alert(e?.response?.data?.message || "Some Technical Issue in getting Agent Login")
      localStorage.setItem("isLogin", "false");
    }

  };

  useEffect(() => {
    let isLogin = localStorage.getItem("isLogin");
    if (isLogin === "true") {
      return navigate("/ticket");
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Flex
      width="full"
      align="center"
      justifyContent="center"
    >
      <Box width="29%" boxShadow="2xl" p="6" rounded="md" bg="white">
        <Box>
          <Heading textAlign="center">Agent Login</Heading>
        </Box>
        <Box mt="30px">
          <form action="" onSubmit={handleSubmit}>
            <FormControl >
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="email"
                id="email"
                value={agentLogin.email}
                onChange={handleChange}
                placeholder="Email"
              />
              <FormLabel mt="10px">Password</FormLabel>
              <Input
                id="password"
                name="password"

                type="password"


                value={agentLogin.password}
                onChange={handleChange}
                placeholder="Password"
              />
              <br />
              <Box mt="1rem" textAlign="center">
                <Button
                  color="white"
                  bg="rgb(213,63,140)"
                  width="50%"
                  type="submit"
                  id='login-button'
                >
                  Login
                </Button>
              </Box>
            </FormControl>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default AgentLogin;
