import axios from "axios";
import { getAzureSignedUrl } from "../../../Http";
import { base64StringToBlob } from "blob-util";
export const uploadFile = async (
  beneficiaryId: string,
  files: any,
  fileType: string
) => {
  try {
    let signedUrlResponse = await getSignedUrl(
      files.fileList[0].name,
      beneficiaryId,
      fileType
    );
    let signedUrl = signedUrlResponse.data.signedUrl;
    let uploadToAzureResponse = await uploadFileToAzure(signedUrl, files);
    return uploadToAzureResponse;
  } catch (error) {
    throw error;
  }
};

const getSignedUrl = async (
  docName: string,
  benId: string,
  docType: string
) => {
  try {
    const signedUrl = await getAzureSignedUrl(docName, benId, docType);
    return signedUrl.data.data;
  } catch (error) {
    console.error('error:', error);
    throw error;
  }
};
const uploadFileToAzure = async (signedUrl: string, files: any) => {
  const contentType = files.fileList[0].type;
  const b64Data = files.base64.split(",")[1];
  const blob = base64StringToBlob(b64Data, contentType);
  // console.log('blob:', blob);
  try {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: signedUrl,
      headers: {
        "x-ms-blob-type": "BlockBlob",
        "x-ms-date": new Date().toUTCString(),
        ContentEncoding: "base64",
        "Content-Type": contentType,
      },
      data: blob,
    };
// console.log("signedUrl:", signedUrl.split("?")[0]);
    const response = await axios.request(config);
    console.log(response);
    return signedUrl.split("?")[0];
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};