import { getSingleBenData } from "../../../Http";

export const getSingleBenDetailsForProfile = async (benId: string) => {
  try {
    const { data } = await getSingleBenData(benId);
    return data;
  } catch (e: any) {
    throw e
  }
};
