import React, { useState } from "react";
import {
  EmergencyContact,
  TicketProps,
  TicketUpdateInterface,
} from "../../../../Helper/TicketInterface";
import { Box, Stack, Text } from "@chakra-ui/layout";
import { Alert } from "@chakra-ui/alert";
import { Button } from "@chakra-ui/button";
import style from "./PointOfContact.module.css";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { Input } from "@chakra-ui/input";
import TicketCancel from "../TicketCancel";
// import { changeDateFormate } from "../../../../Helper/CalculateAge";
import { ticketUpdateById } from "../../../../Http";
import { useParams } from "react-router";
import EmergencyStartedPopup from "../EmergencyManagement/EmergencyPopUp/EmergencyStartedPopup";
import { sendWhatsappMessage } from "../../Action/sendWhatsAppMsg";
import { integrateAmbulance } from "../../Action/integrateAmbulance";
interface PocProps {
  ticketData: TicketUpdateInterface;
  data: TicketProps;
  setPocName: any;
  setPocNumber: any;
  checkAmbulanceDoctorReq: boolean;
  pocName: string, pocNumber: string,
  createPocPayload: (pocDetails: { fullName: string, mobile: string }) => void,
  ticketUpdate:()=>void
  companyEmContacts:any
}
const PointOfContact: React.FC<PocProps> = ({
  ticketData,
  data,
  setPocName,
  setPocNumber,
  checkAmbulanceDoctorReq,
  createPocPayload,
  pocName,
  pocNumber, ticketUpdate,
  companyEmContacts
}) => {
  const [optionChecked, setIsOptionChecked] = useState("Yes");
  const [pocData, setPocData] = useState({
    fullName: "",
    mobile: "",
  });
  const [emergencyContactId, setEmergencyContactId] = useState("");
  const [selectedEmc, setSelectedEmc] = useState<EmergencyContact | null>(null);
  //eslint-disable-next-line
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPressedAddPoc, setAddPoc] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {id}=useParams();
  console.log('companyEmContacts:', companyEmContacts);
  const handleSelectOption = (e: string) => {
    setIsOptionChecked(e);
  };
  const handleSelectEmergencyContact = (e: any) => {
    if (selectedEmc) {
      setEmergencyContactId(selectedEmc.fullName)
      const pocPayload = {
        fullName: selectedEmc.fullName,
        mobile: selectedEmc?.mobile.toString() || selectedEmc?.whatsappNumber.toString()
      }
      createPocPayload(pocPayload)
    }

  }
  const handleAddPoc = () => {
    setPocName("");
    setPocNumber("");
    setEmergencyContactId("");
    setPocData({ fullName: "", mobile: "" })
    setAddPoc(!isPressedAddPoc);
  };
  const handlePocInputChange = (e: any) => {
    const { name, value } = e.target;
    setPocData((prev) => ({
      ...prev, [name]: value
    }));
    const payload = {
      fullName: pocData.fullName,
      mobile: pocData.mobile
    }
    if (name === "fullName") {
      const onlyCharacter = value.replace(/[^A-Za-z]/g, '')
      payload.fullName = onlyCharacter
    } else {
      const numericValue = value.replace(/[^0-9]/g, '').slice(0, 10);
      payload.mobile = numericValue
    }
    createPocPayload(payload)
  };
  const getEmergencyContact = (emList:any)=>{
    // console.log('emList:', emList);
   const MessageToList= emList.map((el:any)=>{
      return(
        el.mobile || el.whatsappNumber
      )
    })
    return MessageToList.length ? MessageToList : [];

  }
  const handleStoreData = async () => {
    const { currentMedicalStatusResponse } = ticketData

    if (!currentMedicalStatusResponse.isItEmergency) {
      alert("Please Enter Emergency Details");
      return;
    }
    if (
      !currentMedicalStatusResponse.isAmbulanceRequired ||
      !currentMedicalStatusResponse.isDoctorConsultationRequired ||
      !currentMedicalStatusResponse.isPatientConscious
    ) {
      alert("Please Enter Medical Details");
      return;
    }
    if (!currentMedicalStatusResponse.currentCondition.length) {
      alert("Please Enter Patient Current Condition");
      return;
    }
    if (!ticketData.patient) {
      alert("Please Select Patient");
      return;
    }
    // if (!typeOfAssistanceRequired) {
    //   alert("Please Select AssistanceRequired");
    //   return;
    // }
    if (currentMedicalStatusResponse.isAmbulanceRequired === "Yes" && !ticketData.patientLocation) {
      alert("Please Select pickUpAddress ");
      return;
    }
    if (currentMedicalStatusResponse.isAmbulanceRequired === "Yes" && !ticketData.hospitalInfo) {
      alert("Please Select Hospital");
      return;
    }
    if (!ticketData.poc?.fullName || !ticketData.poc.mobile) {
      alert("Please Provide POC Details");
      return;
    }
    ////for sending perks///
    // const age = await changeDateFormate(ticketData?.patient?.age);
    ////////need Perk message for
    // const whatsappMessagePayload = {
    //   WATemplate: "notify_doctor_about_patient_2",
    //   params: {
    //     docNumber: benData.basicInfo.mobile,
    //     docName: data.identifiedBeneficiary?.name,
    //     benName: data.identifiedBeneficiary?.name, //from
    //     poc: data?.pocName || pocName,
    //     age: age,
    //     sex: benData?.basicInfo?.gender,
    //     pocContact:
    //       RemoveCountryCodeFromMobileNUmber(pocMobile) || data?.pocNumber,
    //     isAmbulanceRequested: isAmbulanceRequired, //fromData
    //     patientCondition: currentConditionOfPatient || "", //fromData
    //     otherDescription: currentConditionDesc || "", //fromData
    //     isPatientConscious: isPatientConscious, //fromData
    //     beneficiaryId: data?.identifiedBeneficiary?.id, //fromData
    //     tid: params.id,
    //     beneficiaryName: data?.identifiedBeneficiary?.name,
    //     medicalProfileUrl:
    //       "https://www.e-verify.gov/sites/default/files/everify/guides/InstructionsCreatePDFofE-VerifyManual.pdf",
    //   },
    // };

    // integrate Ambulance
    const ticketPayload = {
      ...ticketData,
      ticketStatus: "Work in progress",

    };
    // console.log('ticketPayload:', ticketPayload);
    let emergencyContact = getEmergencyContact(ticketData.patient.emergencyContacts);
    //all em get whatsappMessage
    //and poc
    //and ticketRaisedBY
    let mobile;
    if (data?.ticketRaisedBy && data.ticketRaisedBy.mobile !== undefined) {
      mobile = data.ticketRaisedBy.mobile;
    }
    let sendWhatsappMessagePayload = {
      template: "NOTIFY_EC_ON_EMERGENCY",
      data: {
        beneficiary: ticketData?.patient?.fullName,
        to: [...emergencyContact, ticketData?.poc?.mobile || data?.poc?.mobile, mobile,...companyEmContacts]

      },
    };

    // sendWhatsappMessage(whatsappMessagePayload);
    try {
      if (id) {
        const response = await ticketUpdateById(id, ticketPayload);
        if (response) {
          setShowModal(true)
          sendWhatsAppMessageToEm(sendWhatsappMessagePayload);
        }

      }
    } catch (e) {
      return alert("some Technical Issue")
    }
    setAddPoc(false);

    try {

      let sLat = ticketData.patientLocation?.geoLocation.coordinates[0] || 0.00000001;
      let sLong = ticketData.patientLocation?.geoLocation.coordinates[1] || 0.00000001;
      let sAddress = ticketData.patientLocation?.address || "";

      let dLat = ticketData.hospitalInfo?.geoLocation?.coordinates[0] || 0.00000001;
      let dLong = ticketData.hospitalInfo?.geoLocation?.coordinates[1] || 0.000001;
      let dAddress = ticketData.hospitalInfo?.address || "";

      await integrateAmbulance(sLat, sLong, dLat, dLong, sAddress, dAddress);
      window.location.reload();
      // console.log("Ticket Data ....", ticketData);

    } catch (error: any) {
      console.log(error)
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    ticketUpdate()
  };
  const sendWhatsAppMessageToEm = async (payload: any) => {
    try {
      const response = await sendWhatsappMessage(payload);
      if (response) {
        setIsLoading(true)
      }
    } catch (e: any) {
      alert(e?.response?.data?.message || "Some Technical Issue In sending Wa Msg")
    }

  }
  return (
    <>
      {checkAmbulanceDoctorReq ? (
        <TicketCancel ticketUpdate={ticketUpdate} ticketData={ticketData} />
      ) : (
        <Box>
          <Stack
            mt={"1rem"}
            className={style.card}
            direction={"column"}
            justify={"flex-start"}
          >
            <Text className={style.subTitle}>Step : Point Of Contact</Text>
            <Box>
              <Alert
                className={style.prompt_style}
                status="success"
                variant="left-accent"
                mt="1rem"
              >
                {data.poc
                  ? "Can I put you as a point of contact for coordination purposes?"
                  : !ticketData?.patient
                    ? "Please select an Patient"
                    : ticketData.patient.emergencyContacts.length
                      ? "Please select an emergency contact for POC"
                      : "Please share your Poc Name and Number"}
              </Alert>
              {data.poc && data.poc.mobile && (
                <RadioGroup
                  // isDisabled={emergencyStarted}
                  value={optionChecked}
                  onChange={(e) => handleSelectOption(e)}
                  display={"flex"}
                  gap={"1rem"}
                  mt={"1rem"}
                >
                  <Stack direction="row" spacing={".575rem"} align={"center"}>
                    <Radio value="Yes" size="lg" />
                    <Text>Yes</Text>
                  </Stack>

                  <Stack direction="row" spacing={".575rem"} align={"center"}>
                    <Radio value="No" size="lg" />
                    <Text>No</Text>
                  </Stack>
                </RadioGroup>
              )}
              {((data.poc && data.poc.mobile && optionChecked === "No") || (ticketData.patient &&
                ticketData.patient.emergencyContacts.length > 0)) && (
                  <Box>
                    <RadioGroup
                      value={emergencyContactId}
                      onChange={(e) => handleSelectEmergencyContact(e)}
                      className={style.name_grid}
                    >
                      {ticketData?.patient?.emergencyContacts?.map((el, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Stack
                              className={style.name_card}
                              direction="row"
                              justifyContent={"space-between"}
                              spacing={"1rem"}
                              align={"center"}
                            >
                              <Text>{el?.fullName}</Text>
                              <Radio
                                isDisabled={isPressedAddPoc}
                                onClick={() => setSelectedEmc(el)}
                                value={el?.fullName}
                                size="lg"
                                isChecked={true}
                              />
                            </Stack>
                          </React.Fragment>
                        );
                      })}
                    </RadioGroup>
                  </Box>
                )}
              {ticketData?.patient && ticketData?.patient?.emergencyContacts?.length > 0 && <Box>
                <Button
                  colorScheme="green"
                  width={"20%"}
                  mt={"1rem"}
                  onClick={handleAddPoc}
                >
                  {isPressedAddPoc ? "Close" : "Add Poc"}{" "}
                </Button>

              </Box>}
              {((data.poc && data.poc.mobile && optionChecked === "Yes") || (ticketData.patient &&
                !ticketData.patient.emergencyContacts.length) || (isPressedAddPoc)) && (
                  <Box mt="1rem" className={style.flex}>
                    <Input
                      placeholder="POC Name"
                      name="fullName"
                      value={pocName}
                      onChange={handlePocInputChange}
                    />
                    <Input
                      placeholder="POC Mobile"
                      name="mobile"
                      value={pocNumber}
                      type="tel"
                      onChange={handlePocInputChange}
                    />
                  </Box>
                )
              }
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              mt={"1rem"}
            >
              <Button onClick={handleStoreData} colorScheme="green">
                Start Emergency
              </Button>
            </Box>
          </Stack>
          {showModal && (
            <EmergencyStartedPopup handleCloseModal={handleCloseModal} />
          )}
        </Box>
      )}
    </>
  );
};

export default PointOfContact;
