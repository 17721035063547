import { getAllOffices } from "../../../../../Http";

export const getAllCompany=async()=>{
    try{
     const res=await getAllOffices()
     return res.data.data
    }catch(e){
        throw e;
    }

}