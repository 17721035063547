import axios from "axios";
import apiConfig from "../apiConfig/apiConfig";

const baseUri = apiConfig.api.baseURL;
// const ticketLocalService="http://localhost:3009"

const urls = {
  User_URI: `${baseUri}/auth/api/v1/`,
  Ticket_URI: `${baseUri}/ticket/api/v1`,
  // Ticket_URI: `${ticketLocalService}/api/v1`,
  OZONETEL_URI: `${baseUri}/ozonetel/api/v1`,
  BENEFICIARY_URI: `${baseUri}/beneficiary/api/v1`,
  Hospital_URI: `${baseUri}/hospital/api/v1`,
  Whatsapp_URI: `${baseUri}/whatsapp/api/v1`,
  AZURE_URI: `${baseUri}/azure/api/v1`,
  SSE_URI: `${baseUri}/ticket/api/v1`,
  CALL_URI: `${baseUri}/call/api/v1`,
  Company_URI: `${baseUri}/company/api/v1`,
};
const axiosInstance = axios.create({
  baseURL: "",
  withCredentials: true,
  headers: {
    "content-type": "application/json",
    Accept: "application/json",
  },
});

/// auth-user-service
export const agentLoginFn = (payload: any) =>
  axiosInstance.post(`${urls.User_URI}auth/login`, payload);
export const verifyToken = async () =>
  await axiosInstance.post(`${urls.User_URI}auth/verify-token`);

///ticket-service

export const getAllTicket = (url: string) =>
  axiosInstance.get(`${urls.Ticket_URI}/ticket/get-ticket-by-filter?${url}`);
// axiosInstance.get(`http://localhost:3009/api/v1/get-ticket-by-filter?${url}`);
export const getTicketById = (ticketId: string) =>
  axiosInstance.get(`${urls.Ticket_URI}/ticket/${ticketId}`);

export const ticketUpdateById = (ticketId: string, payload: any) =>
  axiosInstance.patch(`${urls.Ticket_URI}/ticket/${ticketId}`, payload);

export const handleEmergencyPopupClose = (id: string) =>
  axiosInstance.get(`${urls.SSE_URI}/emergency-handled/${id}`);

export const getAllCallLogsByTicketId = (ticketId: string) =>
  axiosInstance.get(`${urls.CALL_URI}/call/get-call-log/${ticketId}`);

export const saveCallDetailsLogs = (payload: any) =>
  axiosInstance.post(`${urls.CALL_URI}/call/save-log`, payload);

///beneficiary-service

export const getAllBeneficiaryBySubId = (subId: string) =>
  axiosInstance.get(
    `${urls.BENEFICIARY_URI}/beneficiary/find-by-subscriber-id/${subId}`
  );

export const getSingleBenData = (benId: string) =>
  axiosInstance.get(`${urls.BENEFICIARY_URI}/beneficiary/find-by-id/${benId}`);

export const getBenByMobile = (mobile: string) =>
  axiosInstance.get(
    `${urls.BENEFICIARY_URI}/beneficiary/find-by-mobile-number-or-wp-number/${mobile}`
  );

export const getProfilePerk = (benId: string) =>
  axiosInstance.get(
    `${urls.BENEFICIARY_URI}/beneficiary/medical-info-perk/${benId}`
  );

export const getMedicalInfo = (benId: string) =>
  axiosInstance.get(
    `${urls.BENEFICIARY_URI}/beneficiary/medical-info-record/${benId}`
  );
export const getBenByCompanyName=(payload:any)=>axiosInstance.post(`${urls.BENEFICIARY_URI}/beneficiary/search-by-ben-or-company`,payload);

//hospital-service

export const getNearByHospital = (payload: {
  latitude: number;
  longitude: number;
  radius: number;
}) => axiosInstance.post(`${urls.Hospital_URI}/hospital/nearby`, payload);
export const getAllHospitals = () =>
  axiosInstance.get(`${urls.Hospital_URI}/hospital`);

///whatsapp-service
export const sendWhatsAppMessage = (payload: any) =>
  axiosInstance.post(`${urls.Whatsapp_URI}/whatsapp`, payload);
//azure-service
export const getAzureSignedUrl = (
  docName: string,
  benId: string,
  docType: string
) =>
  axiosInstance.post(`${urls.AZURE_URI}/signedUrl/generateSignedUrl`, {
    docName,
    benId,
    docType,
  });
//sse
export const sseRegister = `${urls.SSE_URI}/register`;

///ozonetel-service
export const callManually = (payload: any) =>
  axiosInstance.post(`${urls.OZONETEL_URI}/agent/dial-manual2`, payload);

//companyService
export const getOfficeDataById = (campusId: string) =>
  axiosInstance.get(`${urls.Company_URI}/company-service/offices/${campusId}`);
export const getAllOffices=()=>axiosInstance.get(`${urls.Company_URI}/company-service/offices`)  

// Authentication .
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // console.log(originalRequest,'originalRequest ****************************');
    // console.log(error,'***********');
    // Handle unauthorized errors (status 401)
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        // Attempt to refresh the access token using a POST request to refresh endpoint
        await axios.post(
          `${urls.User_URI}auth/refresh-token`,
          {},
          {
            withCredentials: true, // Ensure credentials are sent with the refresh token request
          }
        );
        // Retry the original request
        return axiosInstance(originalRequest);
      } catch (err) {
        // Handle errors that occur during the refresh token request
        // console.log('err:', err);
        localStorage.setItem("isLogin", "false");
        window.location.reload();
        // const localStorageUpdatedEvent = new Event("localStorageUpdated");
        // window.dispatchEvent(localStorageUpdatedEvent);
        // Redirect to login page, show an error message, or handle as appropriate
        // throw err; // Throw the error to indicate that the request failed
      }
      localStorage.setItem("isLogin", "false");
      window.location.reload();
    }
    // For other errors, just throw the error to be handled by the caller
    throw error;
  }
);

export default axiosInstance;
