export const calculateAge = (dateOfBirth:string) => {
    const today = new Date();
    const birthDateObj = new Date(dateOfBirth);
    let calculatedAge = today.getFullYear() - birthDateObj.getFullYear();
    const birthMonth = birthDateObj.getMonth() + 1;
    const currentMonth = today.getMonth() + 1;
    const monthDifference = currentMonth - birthMonth;
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      calculatedAge--;
    }
    let checkIsGreaterOneYear;
    if (calculatedAge < 1) {
      if (monthDifference === 0) {
        checkIsGreaterOneYear = `${monthDifference}Month's`;
      }
      if (monthDifference < 0) {
        checkIsGreaterOneYear = `Invalid month you entered`;
      } else {
        checkIsGreaterOneYear = `${monthDifference} Months`;
      }
    } else {
      checkIsGreaterOneYear = `${calculatedAge} Years`;
    }
    return checkIsGreaterOneYear;
  };
  