import axios, { AxiosRequestConfig } from 'axios';

export const trackAmbulanceStatus = async (requestId: string): Promise<any> => {
    const data = `trip_id_array%5B%5D=${requestId}`;

    const config: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.pococare.com/requsest_status_ajax',
        data: data
    };

    try {
        const response = await axios.request(config);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

