import { Button } from '@chakra-ui/button'
// import { Input } from '@chakra-ui/input'
import { Box, Text } from '@chakra-ui/layout'
import style from "../EmergencyManagement/EmergencyManagement.module.css"
import React, { useEffect, useState } from 'react'
import { AiFillCheckSquare, AiOutlineCheckSquare } from 'react-icons/ai'
import { TicketProps } from '../../../../Helper/TicketInterface'
import { sendWhatsappMessage } from '../../Action/sendWhatsAppMsg'
import { useNavigate, useParams } from 'react-router'
import { ticketUpdateById } from '../../../../Http'
import { trackAmbulanceStatus } from '../../Action/ambulanceStatus'
interface AmbulanceProps {
    data: TicketProps
    companyEmContacts:any
}
const AmbulanceTracking: React.FC<AmbulanceProps> = ({ data, companyEmContacts }) => {
    // eslint-disable-next-line
    const [ambulanceTrackingLink, setAmbulanceTrackingLink] = useState("");
    const [isDispatchedAMbulance, setIsDispatchedAMbulance] = useState(false);
    const [isPickupLocation, setIsisPickupLocation] = useState(false);
    const [isPickupPatient, setIsPickupPatient] = useState(false);
    const [isPatientDropped, setIsPatientDropped] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate()
    const getEmergencyContact = (emList: any) => {
        // console.log('emList:', emList);
        const MessageToList = emList.map((el: any) => {
            return (
                el.mobile || el.whatsappNumber
            )
        })
        return MessageToList.length ? MessageToList : [];

    }
    const handleAmbulanceDispatch = async () => {
        if (data.ticketStatus === "New") {
            // return alert("Please Select Start Emergency")
            return
        }
        setIsDispatchedAMbulance(true);
        let mobile;
        if (data?.ticketRaisedBy && data.ticketRaisedBy.mobile !== undefined) {
            mobile = data.ticketRaisedBy.mobile;
        }
        const ecList = getEmergencyContact(data?.patient?.emergencyContacts);
        let waMessagePayload = {
            template: "NOTIFY_EC_ON_AMBULANCE_BOOKING_WITHOUT_TRACKING",
            data: {
                to: [...ecList, mobile, data?.poc?.mobile || "1234567899",...companyEmContacts|| "1234567899"],

                beneficiary: data?.ticketRaisedBy?.fullName || "N/A",
                trackingLink: `Please click on the link below to track the ambulance: ${ambulanceTrackingLink} `,
            },
        };

        const updateTicketPayload = {
            ambulanceTask: {
                status: "assigned"
            }

        };
        await updateTicket(id, updateTicketPayload, waMessagePayload);

    };
    const handlePickupLocation = async () => {
        if (data.ticketStatus === "New") {
            // return alert("Please Select Start Emergency")
            return
        }
        setIsisPickupLocation(true);

        let mobile;
        if (data?.ticketRaisedBy && data.ticketRaisedBy.mobile !== undefined) {
            mobile = data.ticketRaisedBy.mobile;
        }
        const ecList = getEmergencyContact(data?.patient?.emergencyContacts);
        let waMessagePayload = {
            template: "NOTIFY_EC_ON_AMBULANCE_ARRIVAL",
            data: {
                to: [...ecList, mobile, data?.poc?.mobile || "1234567899",...companyEmContacts||"1234567899"],

                beneficiary: data?.ticketRaisedBy?.fullName || "N/A",
            },
        };
        const updateTicketPayload = {
            ambulanceTask: {
                status: "onRoutePatientLocation"
            }

        };
        await updateTicket(id, updateTicketPayload, waMessagePayload);
    };

    const handlePickupPatient = async () => {
        if (data.ticketStatus === "New") {
            // return alert("Please Select Start Emergency")
            return
        }
        setIsPickupPatient(true);
        let mobile;
        if (data?.ticketRaisedBy && data.ticketRaisedBy.mobile !== undefined) {
            mobile = data.ticketRaisedBy.mobile;
        }
        const ecList = getEmergencyContact(data?.patient?.emergencyContacts);
        let waMessagePayload = {
            template: "NOTIFY_EC_ON_PICKUP",
            data: {
                to: [...ecList, mobile, data?.poc?.mobile || "1234567899",...companyEmContacts|| "1234567899"],
                beneficiary: data?.ticketRaisedBy?.fullName || "N/A",
                hospital: data?.hospitalInfo?.address,
            },
        };
        const updateTicketPayload = {
            ambulanceTask: {
                status: "patientPicked"
            }

        };
        await updateTicket(id, updateTicketPayload, waMessagePayload);
    };

    const handlePatientDropped = async () => {
        if (data.ticketStatus === "New") {
            // return alert("Please Select Start Emergency")
            return
        }
        setIsPatientDropped(true);
        let mobile;
        if (data?.ticketRaisedBy && data.ticketRaisedBy.mobile !== undefined) {
            mobile = data.ticketRaisedBy.mobile;
        }
        const ecList = getEmergencyContact(data?.patient?.emergencyContacts);
        let waMessagePayload = {
            template: "NOTIFY_EC_ON_DROPOFF",
            data: {
                to: [...ecList, mobile, data?.poc?.mobile || "1234567899",...companyEmContacts|| "1234567899"],
                beneficiary: data?.ticketRaisedBy?.fullName || "N/A   ",
                hospital: data?.hospitalInfo?.address,
            },
        };
        const updateTicketPayload = {
            ambulanceTask: {
                status: "patientDropped"
            }

        };
        await updateTicket(id, updateTicketPayload, waMessagePayload);
    };
    const handleEmergencyResolved = async () => {
        const resolvedPayload = {

            ticketStatus: "Completed",
        };
        await updateTicket(id, resolvedPayload, "");
        // ShowAlert("success", "Emergency Resolved");
        // localStorage.clear("ticketDataLocalLocal"); // no need now bcz everything now changed as dynamic
        // // if suppose some data data present in the local it will remove
        navigate("/");
    };

    const trackAmbulance = () => {
        const id = localStorage.getItem('tripId') || "";

        if (id !== "") {
            let droppedCalled = false;
            let dispatchCalled = false;
            let pickupLocationCalled = false;
            let pickupPatientCalled = false;
            setInterval(async () => {
                let response = await trackAmbulanceStatus(id);

                if (response.length < 1 && !isPatientDropped && !droppedCalled) {
                    setIsPickupPatient(true);
                    setIsisPickupLocation(true);
                    setIsDispatchedAMbulance(true);
                    localStorage.clear();
                    droppedCalled = true;
                    handlePatientDropped();
                }


                if (response[0]?.trip_detail.is_provider_status === 2 && !isDispatchedAMbulance && response.length > 0 && !dispatchCalled) {
                    dispatchCalled = true
                    handleAmbulanceDispatch();
                }

                if (response[0]?.trip_detail.is_provider_status === 4 && !isPickupLocation && response.length > 0 && !pickupLocationCalled) {
                    pickupLocationCalled = true
                    handlePickupLocation();
                }

                if (response[0]?.trip_detail.is_provider_status === 6 && !isPickupPatient && response.length > 0 && !pickupPatientCalled) {
                    pickupPatientCalled = true
                    handlePickupPatient();
                }

            }, 3000)
        }

    }

    useEffect(() => {
        trackAmbulance()
    }, []) // eslint-disable-line



    const updateTicket = async (id: any, ticketPayload: any, waMessagePayload: any) => {
        try {
            if (id) {
                const response = await ticketUpdateById(id, ticketPayload);
                if (response) {
                    if (waMessagePayload !== "") {
                        sendWhatsAppMessageToEm(waMessagePayload);
                    }
                    // alert("Emergency Resolved")
                }

            }
        } catch (e: any) {
            return alert(e?.response?.data?.message || "Some Technical Issue in Ticket Updating")
        }
    }
    const sendWhatsAppMessageToEm = async (payload: any) => {
        try {
            const response = await sendWhatsappMessage(payload);
            if (response) {
                console.log("Sss")
            }
        } catch (e: any) {
            alert(e?.response?.data?.message || "Some Technical Issue in Sending Wa Msg")
        }
    }

    return (
        <>
            {data?.currentMedicalStatusResponse?.isAmbulanceRequired === "No" ? (
                ""
            ) : (
                <Box mt={"1rem"} className={style.card}>
                    <Text className={style.subTitle}>Ambulance Tracking </Text>
                    {/* <Input
                        value={ambulanceTrackingLink}
                        onChange={(e) => setAmbulanceTrackingLink(e.target.value)}
                        marginTop={"10px"}
                        placeholder="Ambulance Tracking Link"
                    /> */}
                    <Box className={style.flex}>
                        <Box
                            mt={"1rem"}
                            onClick={handleAmbulanceDispatch}
                            className={style.hor_flex}
                        >
                            <Box className={style.center}>
                                {isDispatchedAMbulance ? (
                                    <AiFillCheckSquare fontSize={"2rem"} color="#008400" />
                                ) : (
                                    <AiOutlineCheckSquare fontSize={"2rem"} color="#008400" />
                                )}
                            </Box>
                            <Box>
                                <Text>Ambulance Dispatched</Text>
                            </Box>
                        </Box>
                        <span className={style.border}></span>
                        <Box
                            onClick={handlePickupLocation}
                            mt={"1rem"}
                            className={style.hor_flex}
                        >
                            <Box className={style.center}>
                                {isPickupLocation ? (
                                    <AiFillCheckSquare fontSize={"2rem"} color="#008400" />
                                ) : (
                                    <AiOutlineCheckSquare fontSize={"2rem"} color="#008400" />
                                )}
                            </Box>
                            <Box>
                                <Text textAlign={"center"}>Pickup Location</Text>
                            </Box>
                        </Box>
                        <span className={style.border}></span>
                        <Box
                            onClick={handlePickupPatient}
                            mt={"1rem"}
                            className={style.hor_flex}
                        >
                            <Box className={style.center}>
                                {isPickupPatient ? (
                                    <AiFillCheckSquare fontSize={"2rem"} color="#008400" />
                                ) : (
                                    <AiOutlineCheckSquare fontSize={"2rem"} color="#008400" />
                                )}
                            </Box>
                            <Box>
                                <Text>Pickup Patient</Text>
                            </Box>
                        </Box>

                        <span className={style.border}></span>
                        <Box

                            onClick={handlePatientDropped}
                            mt={"1rem"}
                            className={style.hor_flex}
                        >
                            <Box className={style.center}>
                                {isPatientDropped ? (
                                    <AiFillCheckSquare fontSize={"2rem"} color="#008400" />
                                ) : (
                                    <AiOutlineCheckSquare fontSize={"2rem"} color="#008400" />
                                )}
                            </Box>
                            <Box>
                                <Text>Patient Dropped</Text>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Button
                            isDisabled={
                                data?.ticketStatus === "New" ? true : false
                            }
                            onClick={handleEmergencyResolved}
                            mt={"1rem"}
                            colorScheme="green"
                        >
                            Emergency Resolved
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    )
}

export default AmbulanceTracking
