import { getNearByHospital } from "../../../Http";

export const getNearbyHospitals = async (payload: { longitude: number, latitude: number, radius: number }) => {
    try {
        const { data } = await getNearByHospital(payload);
        return data

    } catch (e: any) {
        // console.error("error from getting single ben data");
        // return alert(e?.response?.data?.message||"Some Technical Issue");
        throw e;
    }
};
