import { Alert, Box, Checkbox, Image, Radio, RadioGroup, Stack, Text, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'
import style from "./MedicalDetails.module.css"
import { TicketProps, TicketUpdateInterface } from '../../../Helper/TicketInterface'
import { MultiSelect } from "react-multi-select-component";
import { TfiWheelchair } from "react-icons/tfi";
import { GiNurseFemale } from "react-icons/gi";
import { FaUserPlus } from "react-icons/fa";
interface medicalProps{
    data: TicketProps;
    ticketData: TicketUpdateInterface;
    setTicketData: any;
    handleCheckAmbulanceDoctorReq:any
}
const options = [
    { label: "Accident ", value: "Accident" },
    { label: "Bleeding ", value: "Bleeding" },
    { label: "Burn", value: "Burn" },
    { label: "Breathing Difficulty", value: "BreathingDifficulty" },
    { label: "Severe Pain", value: "SeverePain" },
    { label: "Collapse", value: "Collapse" },
    { label: "Stroke", value: "Stroke" },
    { label: "Fracture", value: "Fracture" },
    { label: "Others", value: "Others" },
];
const MedicalDetails: React.FC<medicalProps> = ({ data, ticketData, setTicketData, handleCheckAmbulanceDoctorReq }) => {
    const checkEmergencyStarted = data?.ticketStatus !== "New"
    // console.log('checkEmergencyStarted:', checkEmergencyStarted)
    const [selected, setSelected] = useState(
        checkEmergencyStarted
            ? data?.currentMedicalStatusResponse?.currentCondition?.map((e) => {
                return {
                    label: e,
                    value: e,
                };
            })
            : []
    );
    const [ambulanceChecked, setIsAmbulanceChecked] = useState(
        checkEmergencyStarted ? data?.currentMedicalStatusResponse?.isAmbulanceRequired : "Yes"
    );
    const [doctorChecked, setIsDoctorChecked] = useState(
        checkEmergencyStarted ? data?.currentMedicalStatusResponse?.isDoctorConsultationRequired : "Yes"
    );
    const [patientChecked, setIsPatientChecked] = useState(
        checkEmergencyStarted ? data?.currentMedicalStatusResponse?.isPatientConscious : "Yes"
    );
    const [otherComment, setOtherComment] = useState(
        checkEmergencyStarted ? data?.currentMedicalStatusResponse?.otherDescription : ""
    );
    const [checkBox, setCheckbox] = useState<any>(() => {
        const typeOfAssistanceRequired = checkEmergencyStarted
            ? data?.typeOfAssistanceRequired
            : null;
        // console.log('typeOfAssistanceRequired:', typeOfAssistanceRequired);
        const wheelChair =
            typeOfAssistanceRequired?.wheelChair === false ? false : true;
        const stretcher =
            typeOfAssistanceRequired?.stretcher === false ? false : true;
        const paramedic =
            typeOfAssistanceRequired?.paramedic === false ? false : true;
        const additionalHelp =
            typeOfAssistanceRequired?.additionalHelp === false ? false : true;

        const allDetails =
            typeOfAssistanceRequired !== undefined
                ? wheelChair || stretcher || paramedic || additionalHelp
                : false;

        return {
            wheelChair: typeOfAssistanceRequired ? wheelChair : false,
            stretcher: typeOfAssistanceRequired ? stretcher : false,
            paramedic: typeOfAssistanceRequired ? paramedic : false,
            additionalHelp: typeOfAssistanceRequired ? additionalHelp : false,
            allDetails,
        };
    });
    const getCurrentCondition = (patientConditionData: any) => {
        const res = patientConditionData?.map((el:any) => {
            return el.value;
        });
        return res?.join(",");
    };
    const handleCheckboxChanges = (value:any) => {
        setCheckbox((prevCheckboxes:any) => ({
            ...prevCheckboxes,
            [value]: !prevCheckboxes[value],
        }));
        setTicketData({
            ...ticketData,
            typeOfAssistanceRequired: {
                ...checkBox,
                [value]: !checkBox[value],
            },
        });
    };
    //ambulance
    let ambulanceAndDoctorData = {
        ambulanceReq: ambulanceChecked,
        doctorReq: doctorChecked,
    };
    const handleNeedAmbulance = (e:string) => {
        setIsAmbulanceChecked(e);
        setTicketData({
            ...ticketData,
            currentMedicalStatusResponse: {
                ...ticketData.currentMedicalStatusResponse,
                isAmbulanceRequired: e,
            }
        });
        ambulanceAndDoctorData.ambulanceReq = e;
        handleCheckAmbulanceDoctorReq(ambulanceAndDoctorData);
    };
    const handleChangeDoctorConsultation = (e:string) => {
        setIsDoctorChecked(e);
        setTicketData({
            ...ticketData,
            currentMedicalStatusResponse: {
                ...ticketData.currentMedicalStatusResponse,
                isDoctorConsultationRequired: e,
            }
           
        });
        ambulanceAndDoctorData.doctorReq = e;
        handleCheckAmbulanceDoctorReq(ambulanceAndDoctorData);
    };
    const handleChangePatientConscious = (e:string) => {
        setIsPatientChecked(e);
        setTicketData({
            ...ticketData,
            currentMedicalStatusResponse: {
                ...ticketData.currentMedicalStatusResponse,
                isPatientConscious: e,
            }
          
        });
    };
    const handleChangeCurrentConditionDesc = (value:string) => {
        setOtherComment(value);
        setTicketData({
            ...ticketData,
            currentMedicalStatusResponse: {
                ...ticketData.currentMedicalStatusResponse,
                otherDescription: value,
            }
            
        });
    };
    const handleChangePatientConditionChange = (e:any) => {
        let value = e.map((el:any) => {
            return el.value;
        });
        setSelected(e);
        setTicketData({
            ...ticketData,
            currentMedicalStatusResponse:{
                ...ticketData.currentMedicalStatusResponse,
                currentCondition: value,
            }
        });
    };
    return (
        <>
            {data.ticketStatus!=="New"? (
                <Box
                    mt={".4rem"}
                    className={ style.card }
                >
                    <Box className={style.flex}>
                        <Box mt="1rem">
                            {selected?.length === 0 ? null : (
                                <Text className={style.subTitle_top_Card}>
                                    Current condition of patient ?
                                </Text>
                            )}
                            {selected?.length === 0 ? null : (
                                <Text>{getCurrentCondition(selected)}</Text>
                            )}
                            {otherComment !== "" ? (
                                <Box>
                                    <Text className={style.subTitle_top_Card}>
                                        Other description
                                    </Text>
                                    <Text>ss{otherComment}</Text>
                                </Box>
                            ) : (
                                ""
                            )}
                        </Box>

                        {data?.ticketStatus === "New" ? null : (
                            <Box>
                                <Text className={style.subTitle_top_Card}>
                                    Type of assistance required
                                </Text>

                                <Box mt={"1rem"} display={"flex"} gap={"2rem"}>
                                    <Stack direction={"row"} spacing={"1rem"}>
                                        <Checkbox
                                            isDisabled={true}
                                            size={"lg"}
                                            onChange={() => handleCheckboxChanges("wheelChair")}
                                            isChecked={checkBox.wheelChair}
                                            value="wheelChair"
                                        />
                                        <TfiWheelchair
                                            fontSize={"30px"}
                                            fontWeight={600}
                                            color="#6c726c"
                                        />
                                    </Stack>

                                    <Stack direction={"row"} spacing={"2rem"}>
                                        <Checkbox
                                            isDisabled={true}
                                            onChange={() => handleCheckboxChanges("stretcher")}
                                            isChecked={checkBox.stretcher}
                                            size={"lg"}
                                            value="stretcher"
                                        />
                                        <Image src="/Images/stretcher_inactive.svg" />
                                    </Stack>
                                    <Stack direction={"row"} spacing={"2rem"}>
                                        <Checkbox
                                            isDisabled={true}
                                            size={"lg"}
                                            onChange={() => handleCheckboxChanges("paramedic")}
                                            isChecked={checkBox.paramedic}
                                            value="nurse"
                                        />
                                        <GiNurseFemale
                                            fontSize={"30px"}
                                            fontWeight={600}
                                            color="#6c726c"
                                        />
                                    </Stack>
                                    <Stack direction={"row"} spacing={"2rem"}>
                                        <Checkbox
                                            isDisabled={true}
                                            size={"lg"}
                                            onChange={() => handleCheckboxChanges("additionalHelp")}
                                            isChecked={checkBox.additionalHelp}
                                            value="additionalHelp"
                                        />
                                        <FaUserPlus
                                            fontSize={"30px"}
                                            fontWeight={600}
                                            color="#6c726c"
                                        />
                                    </Stack>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box>
                    <Stack
                        mt={"1rem"}
                        className={style.card}
                        direction={"column"}
                        justify={"flex-start"}
                    >
                        <Box>
                            <Text className={style.subTitle}>Step : Medical condition</Text>
                        </Box>
                        <Box className={style.medical_grid}>
                            <Box className={style.card}>
                                <Box>
                                    <Alert
                                        className={style.prompt_style}
                                        status="success"
                                        variant="left-accent"
                                        mb={".5rem"}
                                    >
                                        Do you need ambulance ?
                                    </Alert>
                                </Box>
                                <Box mt={"1rem"}>
                                    <RadioGroup
                                        value={ambulanceChecked}
                                        onChange={(e) => handleNeedAmbulance(e)}
                                        display={"flex"}
                                        gap={"2rem"}
                                    >
                                        <Stack direction={"row"} spacing={"1rem"}>
                                            <Radio value="Yes" />
                                            <Text>Yes</Text>
                                        </Stack>
                                        <Stack direction={"row"} spacing={"1rem"}>
                                            <Radio value="No" />
                                            <Text>No</Text>
                                        </Stack>
                                    </RadioGroup>
                                </Box>
                            </Box>
                             <Box className={style.card}>
                                <Box>
                                    <Alert
                                        className={style.prompt_style}
                                        status="success"
                                        variant="left-accent"
                                        mb={".5rem"}
                                    >
                                        Do you need doctor consultation ?
                                    </Alert>
                                </Box>
                                <Box mt={"1rem"}>
                                    <RadioGroup
                                        value={doctorChecked}
                                        onChange={(e) => handleChangeDoctorConsultation(e)}
                                        display={"flex"}
                                        isDisabled={ambulanceChecked === "Yes"}
                                        gap={"2rem"}
                                    >
                                        <Stack direction={"row"} spacing={"1rem"}>
                                            <Radio value="Yes" />
                                            <Text>Yes</Text>
                                        </Stack>
                                        <Stack direction={"row"} spacing={"1rem"}>
                                            <Radio value="No" />
                                            <Text>No</Text>
                                        </Stack>
                                    </RadioGroup>
                                </Box>
                            </Box>
                            <Box className={style.card}>
                                <Box>
                                    <Alert
                                        className={style.prompt_style}
                                        status="success"
                                        variant="left-accent"
                                        mb={".5rem"}
                                    >
                                        Is the patient conscious ?
                                    </Alert>
                                </Box>
                                <Box mt={"1rem"}>
                                    <RadioGroup
                                        value={patientChecked}
                                        onChange={(e) => handleChangePatientConscious(e)}
                                        display={"flex"}
                                        gap={"2rem"}
                                    >
                                        <Stack direction={"row"} spacing={"1rem"}>
                                            <Radio value="Yes" />
                                            <Text>Yes</Text>
                                        </Stack>
                                        <Stack direction={"row"} spacing={"1rem"}>
                                            <Radio value="No" />
                                            <Text>No</Text>
                                        </Stack>
                                    </RadioGroup>
                                </Box>
                            </Box> 
                        </Box>
                         {doctorChecked === "No" && ambulanceChecked === "No" ? null : (
                            <>
                                <Box>
                                    <Box mt="1rem">
                                        <Alert
                                            className={style.prompt_style}
                                            status="success"
                                            variant="left-accent"
                                            mb={".5rem"}
                                        >
                                            Current condition of patient ?
                                        </Alert>
                                        <MultiSelect
                                            options={options}
                                            value={selected}
                                            onChange={(e:any) => handleChangePatientConditionChange(e)}
                                            hasSelectAll={false}
                                            disableSearch={true}
                                            labelledBy="myLabel"
                                        />
                                    </Box>
                                </Box>
                                <Box mt="1rem">
                                    <Alert
                                        className={style.prompt_style}
                                        status="success"
                                        variant="left-accent"
                                        mb={".5rem"}
                                    >
                                        Other description
                                    </Alert>
                                    <Textarea
                                        value={otherComment}
                                        placeholder="Other description"
                                        onChange={(e) =>
                                            handleChangeCurrentConditionDesc(e.target.value)
                                        }
                                    />
                                </Box>
                            </>
                        )}
                        {ambulanceChecked === "No" ? null : (
                            <Box>
                                <Alert
                                    mt={"1rem"}
                                    className={style.prompt_style}
                                    status="success"
                                    variant="left-accent"
                                    mb={".5rem"}
                                >
                                    Type of assistance required
                                </Alert>
                                <Box mt={"1rem"} display={"flex"} gap={"2rem"}>
                                    <Stack direction={"row"} spacing={"1rem"}>
                                        <Checkbox
                                            size={"lg"}
                                            onChange={() => handleCheckboxChanges("wheelChair")}
                                            isChecked={checkBox.wheelChair}
                                            value="wheelChair"
                                        />
                                        <TfiWheelchair
                                            fontSize={"30px"}
                                            fontWeight={600}
                                            color="#008401"
                                        />
                                    </Stack>
                                    <Stack direction={"row"} spacing={"2rem"}>
                                        <Checkbox
                                            onChange={() => handleCheckboxChanges("stretcher")}
                                            isChecked={checkBox.stretcher}
                                            size={"lg"}
                                            value="stretcher"
                                        />
                                        <Image src="/Images/stretcher.svg" />
                                    </Stack>
                                    <Stack direction={"row"} spacing={"2rem"}>
                                        <Checkbox
                                            size={"lg"}
                                            onChange={() => handleCheckboxChanges("paramedic")}
                                            isChecked={checkBox.paramedic}
                                            value="paramedic"
                                        />
                                        <GiNurseFemale
                                            fontSize={"30px"}
                                            fontWeight={600}
                                            color="#008401"
                                        />
                                    </Stack>
                                    <Stack direction={"row"} spacing={"2rem"}>
                                        <Checkbox
                                            size={"lg"}
                                            onChange={() => handleCheckboxChanges("additionalHelp")}
                                            isChecked={checkBox.additionalHelp}
                                            value="additionalHelp"
                                        />
                                        <FaUserPlus
                                            fontSize={"30px"}
                                            fontWeight={600}
                                            color="#008401"
                                        />
                                    </Stack>
                                </Box>
                            </Box>
                        )}
                    </Stack>
                </Box>
            )}
        </>
    )
}

export default MedicalDetails
