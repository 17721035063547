import { Box, Button, Card, CardBody, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import style from "../EmergencyManagement/EmergencyManagement.module.css"
import { sendWhatsappMessage } from '../../Action/sendWhatsAppMsg'
import { TicketProps } from '../../../../Helper/TicketInterface'
interface AmbulanceProps {
    data: TicketProps
}

const AmbulanceMessage: React.FC<AmbulanceProps> = ({ data}) => {
    // console.log('data:', data)
    const [ambulanceMessageSend, setAmbulanceMessageSend] = useState(false);

    const sendAmbulanceMessage=()=>{
        let reqField;
        if(data?.typeOfAssistanceRequired){
        reqField = Object.keys(data?.typeOfAssistanceRequired)
                .filter((key) => (data.typeOfAssistanceRequired as Record<string, Boolean>)[key] === true)
                .join(', ');
        }

        setAmbulanceMessageSend(true)
        
        const whatsappMessagePayload = {
            template: "NOTIFY_AGENT_ABOUT_AMBULANCE",
            data: {
                to:[data?.agent?.mobile],
                beneficiary: data?.ticketRaisedBy?.fullName||"N/A",
                beneficiaryUId: data?.ticketRaisedBy?.benId, //from
                poc:data?.poc?.fullName,
                pocNumber: data?.poc?.mobile,
                isBeneficiaryConcious: data.currentMedicalStatusResponse.isPatientConscious,
                patientCondition: data?.currentMedicalStatusResponse?.currentCondition?.join(",")||"N/A", //fromData
                beneficiaryLocation:data?.patientLocation?.address,
                latlon:data.patientLocation?.geoLocation?.coordinates?.join(",")||"N/A",
                isReachableByAmbulance: data?.patientLocation?.isReachableByAmbulance,
                isLiftAvailable:data?.patientLocation?.isLiftAvailable,
                additionalRequiurements: reqField||"N/A",
                hospital: data?.hospitalInfo?.address||"N/A",
            },

            
            
        };
        sendWhatsAppMessageToEm(whatsappMessagePayload)
    
    }
    const sendWhatsAppMessageToEm = async (payload: any) => {
        try {
            const response = await sendWhatsappMessage(payload);
            if (response) {
                console.error("Sss")
            }
        } catch (e: any) {
            alert(e?.response?.data?.message || "Some Technical Issue in Sending Whatsapp Msg")
        }
    }
    return (
        <>
            {data?.currentMedicalStatusResponse?.isAmbulanceRequired === "No" ? (
                ""
            ) : (
                <Box mt={"1rem"} className={style.card}>
                    <Text className={style.subTitle}>Ambulance booking</Text>
                    <Box className={style.gridSystem_pickup}>
                        <Box>
                            <Card bg={"#F5F9F9"}>
                                <CardBody>
                                    <Text fontSize={"1rem"} color={"green"} fontWeight={600}>
                                        Pick-Up Location
                                    </Text>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Box>
                                            <Text fontWeight={600}>
                                                {data?.patientLocation?.address}
                                            </Text>
                                        </Box>
                                    </Box>
                                </CardBody>
                            </Card>
                        </Box>
                        <Box>
                            <Card bg={"#F5F9F9"}>
                                <CardBody>
                                    <Text fontSize={"1rem"} color={"green"} fontWeight={600}>
                                        Drop Location
                                    </Text>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Box>
                                            <Text fontWeight={600}>
                                                {data?.hospitalInfo?.address}
                                            </Text>
                                        </Box>
                                    </Box>
                                </CardBody>
                            </Card>
                        </Box>
                    </Box>
                    {ambulanceMessageSend ? (
                        ""
                    ) : (
                        <Box
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                                    <Button
                                    isDisabled={
                                        data?.ticketStatus === "New" ? true : false
                                    }
                              
                                onClick={sendAmbulanceMessage}
                                mt={"1rem"}
                                colorScheme="green"
                            >
                                Send Ambulance Message
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
        </>
    )
}

export default AmbulanceMessage

