import { ticketUpdateById } from "../../../Http"

export const updateTicket=async(ticketId:string,payload:any)=>{
    try{
        const response = await ticketUpdateById(ticketId,payload);
        return response;
    }catch(e:any){
        console.error("error from updating Ticket")
        // alert(e?.response?.data?.message||"Some technical issue in updating ticket")
        throw e;
    }

}