import { Box, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Select, Text } from '@chakra-ui/react'
import React from 'react'
import { TicketProps, TicketUpdateInterface } from '../../../../Helper/TicketInterface';
// import { ticketUpdateById } from '../../../../Http';
import { updateTicket } from '../../Action/updateTicket';
interface CallerProps {
    ticketData: TicketUpdateInterface;
    setTicketData: any;
    getData: TicketProps;
    ticketId: any;
    handleCloseModal: any
    agentName: any
}
const CallerPopup: React.FC<CallerProps> = ({ ticketData, setTicketData, getData, ticketId, agentName, handleCloseModal }) => {
    const emUsers = [
        { agentId: "amitav.a@pococare.com", agent: "Amitav", mobile: "9650777139" },
        {
            agentId: "bharathkumar.n@pococare.com",
            agent: "Bharath",
            mobile: "8904202256",
        },
        { agentId: "mahesh.r@pococare.com", agent: "Mahesh", mobile: "7299950882" },
        {
            agentId: "mounika.s@pococare.com",
            agent: "Mounika",
            mobile: "7406285008",
        },
        { agentId: "pradip.c@pococare.com", agent: "Pradip", mobile: "7702778917" },
        { agentId: "sanket.j@pococare.com", agent: "Sanket", mobile: "9643331297" },
        { agentId: "ranga@pococare.com", agent: "Ranga", mobile: "8056066766" },
        { agentId: "vedic.c@pococare.com", agent: "Vedic", mobile: "8884600613" },
    ];
    const handleChooseAgent = async (e: any) => {
        const value = e.target.value;
        ///////
        agentName(value);
        const agentDetails = emUsers.filter((el) => el.agent === value);
        updateAgent(agentDetails[0]);
         handleCloseModal();
    };
    const updateAgent = async (item: any) => {
        const payload = {
             agent: {
                email: item.agentId,
                fullName: item.agent,
                mobile: item.mobile,
            },
        };
        try {

            await updateTicket(ticketId, payload);

        } catch (e: any) {
            e?.response?.data?.message ? alert(e.response.data.message) : alert("Some Technical Issue in Update Ticket")

        }
        handleCloseModal();
    };
    return (
        <>
            <Modal
                size={"xl"}
                blockScrollOnMount={true}
                isOpen={true}
                onClose={handleCloseModal}
                //   closeOnBlur={false}
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Agent for this ticket</ModalHeader>
                    {/* <ModalCloseButton /> */}
                    <ModalBody>
                        <Box>
                            <Text mb="1rem" fontWeight="600">
                                {" "}
                                {getData?.agent?.email ? `Assigned to ${getData?.agent?.email}` : "None"}
                            </Text>
                        </Box>
                        <Box>
                            <Text>
                                Select user id for this ticket.
                                <br /> (Note : Outgoing calls made using this tool will be
                                connected to selected id)
                            </Text>
                        </Box>
                        <Select
                            mb={"1rem"}
                            mt={"1rem"}
                            onChange={(e) => handleChooseAgent(e)}
                            placeholder="Select option"
                        >
                            <option value="Amitav">Amitav</option>
                            <option value="Bharath">Bharath</option>
                            <option value="Mahesh">Mahesh</option>
                            <option value="Mounika">Mounika</option>
                            <option value="Pradip">Pradip</option>
                            <option value="Sanket">Sanket</option>
                            <option value="Ranga">Ranga</option>
                            <option value="Vedic">Vedic</option>
                        </Select>
                    </ModalBody>
                </ModalContent>
            </Modal>

        </>
    )
}

export default CallerPopup
