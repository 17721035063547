export const geoCode = async (payload:any) => {
  // const apiKey = "AIzaSyCyMav3OTEct_1qw15ZeqxTmdnyS6onYD8";
  try {
    const address = encodeURIComponent(payload);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCyMav3OTEct_1qw15ZeqxTmdnyS6onYD8`;
    const response = await fetch(url);
    const data = await response.json();
    // console.log("data:", data);
    if (data.status === "OK" && data.results.length > 0) {
      const result = data.results[0];
      const lat = result.geometry.location.lat;
      const lng = result.geometry.location.lng;
      return { lat, lng };
    } else {
      // throw new Error("Geocoding failed");
      // alert("Error geocoding address please Enter Proper Address");
    }
  } catch (error) {
    alert("Error geocoding address:");
    throw error;
  }
};
