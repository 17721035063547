import { Box, Divider, Flex, Heading, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import profileStyle from "./BasicDetails.module.css"
import { AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import QRCode from "qrcode.react";
import { BenDataProps } from '../../Helper/BeneInterface';
import ProfileProgress from '../../Pages/ProfilePerk/Componenets/ProfileProgress/ProfileProgress';
import { calculateAge } from '../../Pages/ProfilePerk/ProfileAction/calculateAge';
import { useLocation } from 'react-router-dom';

const ProfileBasicDetail: React.FC<{ data: BenDataProps }> = ({ data }) => {

    const location = useLocation();
    const [completionPercentage, setCompletionPercentage] = useState(0);

    useEffect(() => {
        const check = data?.progress || 0;
        const totalPercentage = Math.round((check / 6) * 100);
        setCompletionPercentage(totalPercentage);
    }, [completionPercentage, data]);

    return (
        <Box className={profileStyle.ben_PersonalDetails}>
            <Box className={profileStyle.personalDetails_Top}>
                <Box>
                    <Heading textAlign={"center"} size='md' mt={"4"} mb={"6"}>Your Pococare Emergency Ready Kit (PERK)</Heading>
                </Box>
                <Box className={profileStyle.personDetails_Child}>
                    <Box className={profileStyle.personDetails_left}>
                        <Box className={profileStyle.personDetails_right_img}>
                            {data?.personalInfo?.image ? (
                                <Image width={"100%"} src={data?.personalInfo?.image || ""} alt="Invalid" />
                            ) : (
                                <Text className={profileStyle.benName_center}>
                                    {data.personalInfo.fullName?.split("")[0].toUpperCase()}
                                </Text>
                            )}
                            {location.pathname.includes('profile-perk')?(<Box className={profileStyle.circleProgress}>
                                <ProfileProgress
                                    percentage={completionPercentage}
                                    strokeWidth={3}
                                    circleSize={"115px"}
                                />
                            </Box>):""}
                        </Box>
                        <Box className={profileStyle.nameContainer}>
                            <Text className={profileStyle.benName} fontSize={"22px"}>{data.personalInfo.fullName || ""}</Text>
                            <Text className={profileStyle.relationToBen} fontSize={"18px"}>
                                {data?.personalInfo?.relationshipToSubscriber}
                            </Text>
                        </Box>
                    </Box>
                    <Box className={profileStyle.personDetails_right}>
                        <Box className={profileStyle.personalDetails_RightDet}>

                            <Box textAlign={"center"}>
                                <Flex justifyContent={"space-around"}>
                                    <QRCode
                                        value={"https://pococare.com/trigger-emergency/" + data?._id}
                                        id="canvas"
                                        size={120}
                                        level={"H"}
                                        includeMargin={true}
                                        imageSettings={{
                                            src: "/Images/transparentLogo.png",
                                            x: 30,
                                            y: 30,
                                            height: 30,
                                            width: 30,
                                            excavate: true,
                                        }}
                                    />
                                </Flex>
                                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                                    Scan me during an emergency
                                </p>
                                <p
                                    style={{
                                        fontSize: "15px",
                                        color: "blue",
                                        fontWeight: "bold",
                                        marginTop: "10px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <a
                                        href="https://csg1003200234760c51.blob.core.windows.net/poco-prod/Pococare_QR_Code_Manual.pdf"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Click here for the QR code manual
                                    </a>
                                </p>
                            </Box>
                        </Box>
                    </Box>
                    {/* //child */}
                    <Box className={profileStyle.topColor}></Box>
                </Box>
                <Box className={profileStyle.personalDetails_ChildMobile}>
                    <Box>
                        <Flex justifyContent={"space-around"}>
                            <QRCode
                                value={"https://pococare.com/trigger-emergency/" + data?._id}
                                id="canvas"
                                size={120}
                                level={"H"}
                                includeMargin={true}
                                imageSettings={{
                                    // title: "avc",
                                    src: "/Images/transparentLogo.png",
                                    x: 30,
                                    y: 30,
                                    height: 30,
                                    width: 30,
                                    excavate: true,
                                }}
                            />
                        </Flex>
                        <p style={{ fontWeight: "bold", textAlign: "center" }}>
                            Scan me during an emergency
                        </p>
                        <p
                            style={{
                                fontSize: "15px",
                                color: "blue",
                                fontWeight: "bold",
                                marginTop: "10px",
                                cursor: "pointer",
                                textAlign: "center"
                            }}
                        >
                            <a
                                href="https://csg1003200234760c51.blob.core.windows.net/poco-prod/Pococare_QR_Code_Manual.pdf"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Click here for the QR code manual
                            </a>
                        </p>
                    </Box>
                    <Box className={profileStyle.nameContainer}>
                        <Text className={profileStyle.benName} fontSize={"18px"} textAlign={"center"}>{data.personalInfo.fullName} ({data?.personalInfo?.relationshipToSubscriber}) {completionPercentage} %</Text>
                    </Box>
                </Box>
                <Divider borderWidth={"2px"} marginTop={"15px"} />
            </Box>
            <Box className={profileStyle.personalDetails_Bottom}>
                <Box className={profileStyle.profileDetails_Bot_child}>
                    <Box className={profileStyle.profileDetails_Bot_left}>
                        <Box>
                            <Text className={profileStyle.subTitle}>Basic Info</Text>
                            <Flex align={"center"} gap={"13px"} marginTop={"10px"}>

                                <AiOutlineMail
                                    fontSize="20px"
                                    className={profileStyle.icon_Remove}
                                />
                                {/* <Text className={profileStyle.relationToBen} fontSize={"18px"}>
                                    {data?.personalInfo?.relationshipToSubscriber?.toLowerCase() === "self"
                                        ? subscriber.personalInfo.email
                                        : data.personalInfo.email || "Update now!"}
                                </Text> */}
                            </Flex>
                        </Box>
                    </Box>
                    <Box className={profileStyle.profileDetails_Bot_right}>
                        <Box className={profileStyle.contactDetails_Right_det}>
                            <Flex align={"center"} gap={"10px"} >
                                <BsTelephone
                                    fontSize="20px"
                                    className={profileStyle.icon_Remove}
                                />

                                <Text className={profileStyle.contact_Number}>
                                    {data?.personalInfo?.mobile?.number || "Update now!"}
                                </Text>
                            </Flex>
                            <Box className={""}>
                                <Flex align={"center"} gap={"10px"}>
                                    <AiOutlineWhatsApp
                                        fontSize="20px"
                                        className={profileStyle.icon_Remove}
                                    />
                                    <Text className={profileStyle.contact_Number}>
                                        {data?.personalInfo?.whatsAppNumber?.number || "Update now!"}
                                    </Text>
                                </Flex>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box className={profileStyle.pendingDetails}>
                    <Box>
                        <Text className={profileStyle.pendingDetail_Title}>
                            Gender
                        </Text>
                        <Text className={profileStyle.penDetails_det}>
                            {data.personalInfo.gender || "Update now!"}
                        </Text>
                    </Box>
                    <Box>
                        <Text className={profileStyle.pendingDetail_Title}>
                            {"Age"} <br></br>
                        </Text>
                        <Text className={profileStyle.penDetails_det}>
                            {data?.personalInfo?.dateOfBirth ? calculateAge(data?.personalInfo?.dateOfBirth) : "Update now!"}
                        </Text>
                    </Box>
                    {/* in feature need to redesign the data base if needed the employmentStatus field need to use this one */}
                    <Box>
                        {/* <Text className={profileStyle.pendingDetail_Title}>
              Employment Status
            </Text>
            <Text className={profileStyle.penDetails_det}>
              {employmentStatus || "---"}
            </Text> */}
                    </Box>
                    <Box></Box>
                </Box>


            </Box>

        </Box>
    )
}

export default ProfileBasicDetail
