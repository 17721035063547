import React, { useState } from "react";
import { TicketUpdateInterface } from "../../../../Helper/TicketInterface";
import { patientProps } from "../../Dashboard";
import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import style from "./PickupLocation.module.css";
import { Address } from "../../../../Helper/BeneInterface";
import { generateUniqueID } from "../../../../Helper/GenerateRandomId";

interface PickupLocationProps {
  ticketData: TicketUpdateInterface;
  patientDetails: patientProps;
  addressData: Address[];
  setAddressData: any;
  getPreferredHospital: (el: Address) => void;
  getPatientAddress: (el: Address) => void;
}

const PickupLocation: React.FC<PickupLocationProps> = ({
  ticketData,
  patientDetails,
  setAddressData,
  addressData,
  getPreferredHospital,
  getPatientAddress,
}) => {
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const inputRef = React.useRef(null);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [isCurrentLocation, setIsCurrentLocation] = useState<boolean | undefined>(undefined)
  //eslint-disable-next-line
  const [addressLabel, setAddressLabel] = useState("");
  const [landmark, setLandmark] = useState("");
  //eslint-disable-next-line
  const [selectedAddressComponents, setSelectedAddressComponents] =
    React.useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  //eslint-disable-next-line
  const [selectedPlace, setSelectedPlace] = React.useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState<Number>();
  const [country, setCountry] = useState<any>("");
  // console.log('currentAddress1Lat:', currentAddress1Lat);
  const [latitude, setLatitude] = useState("");
  //eslint-disable-next-line
  // console.log('currentAddress1Lon:', currentAddress1Lon);
  const [longitude, setLongitude] = useState("");
  const [ambulanceAvailable, setAmbulanceAvailable] = useState<boolean>(false);
  const [liftAvailable, setLiftAvailable] = useState<boolean>(false);
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const autocompleteRef = React.useRef<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]);
  const [currentAddressId, setCurrentAddressId] = useState<number | undefined>();

  const handleModalOpen = () => {
    onOpen();
    setAddress1("");
    setAddress2("");
  };

  const handleInputChange = (event: any) => {

    const inputValue = event.target.value;
    setAddress1(inputValue);
    setSelectedPlace(null);
    setSelectedAddressComponents(null);

    const service = new window.google.maps.places.AutocompleteService();
    if (inputValue.length > 3) {
      const request = {
        input: inputValue,
      };
      setShowDropdown(true);
      service.getPlacePredictions(request, (predictions: any) => {
        setAutocompleteSuggestions(predictions || []);
      });
    }
  };



  const handleSelectAddress = (el: Address, editIndex: number) => {
    // console.log("el:", el);

    if (
      typeof el.isReachableByAmbulance === "boolean" &&
      typeof el.isLiftAvailable === "boolean"
    ) {
      setaPatientCurrentLocation(el);
      setSelectedAddress(el);
      // const addressText = `${el.addressLine2 || ""},${el.landmark}, ${el.addressLine1}, ${el.city}, ${el.state}, ${el.pinCode}`;
      // console.log("@@@@@addressText:@@@@@@@@@@@@@@@@@@@", addressText);

      // getGeolocation(addressText, el);
    } else {
      setSelectedAddress(el);
      setAddress1(el.addressLine1 || "");
      setCity(el.city || "");
      setLatitude(el?.location?.latitude?.toString());
      setLongitude(el?.location?.longitude?.toString());
      setState(el.state || "");
      setPincode(Number(el.pinCode));
      setLandmark(el.landmark || "")
      setCountry(el.country || "");
      setAddressLabel(el.label || "")
      setCurrentAddressId(editIndex);
      setIsCurrentLocation(el.currentLocation)
      onEditOpen();
    }
  };

  const handleCancel = () => {
    setAddress1("");
    setAddress2("");
    setCity("");
    setLandmark("");
    setState("");
    setPincode(undefined);
    setCountry("");
    onClose();
    onEditClose();
    setSelectedAddress(null);

    // setCurrentAddress1("");
    // setCurrentCity("");
    // setCurrentState("");
    // setCurrentCountry("");
    // setCurrentPinCode("");
    // setAmbulanceAvailable("");
    // setLiftAvailable("");
    // setOpenAddressModal(false);
  };
  const handleSuggestionClick = (suggestion: any) => {
    ///this changes i have done basically it set address as the suggestion what we forgot in screen//
    setAddress1(suggestion.description);
    if (!autocompleteRef.current) {
      // autocompleteRef.current.set("place", null);
      // setAddress1(suggestion.description);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId: suggestion.place_id }, (results, status) => {
        if (status === "OK" && results[0]) {
          const place = results[0];
          const addressComponents = place.address_components || [];
          //eslint-disable-next-line
          let address = "";
          let city = "";
          let state = "";
          let country = "";
          let pincode = "";
          let latitude = "";
          let longitude = "";
          // console.log("addressComponents:", addressComponents);
          addressComponents.forEach((component) => {
            const types = component.types;

            if (
              types.includes("street_number") ||
              types.includes("route") ||
              types.includes("sublocality_level_3") ||
              types.includes("sublocality_level_2") ||
              types.includes("sublocality_level_1") ||
              types.includes("premise") ||
              types.includes("locality") ||
              types.includes("plus_code") ||
              types.includes("sublocality") ||
              types.includes("neighborhood")
            ) {
              address += component.long_name + " ";
            }
            if (
              types.includes("locality") ||
              types.includes("administrative_area_level_3")
            ) {
              city = component.long_name;
            }
            if (types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }
            if (types.includes("country")) {
              country = component.long_name;
            }
            if (types.includes("postal_code")) {
              pincode = component.long_name;
            }
            if (place.geometry && place.geometry.location) {
              latitude = place.geometry.location.lat().toString();
              longitude = place.geometry.location.lng().toString();
            }
          });
          // console.log("address:", address);
          // console.log("city:", city);
          // console.log("pincode:", pincode);
          // console.log("state:", state);
          // console.log("latitude:", latitude);
          // console.log("longitude:", longitude);
          setCity(city);
          // setAddress1(address);
          setCountry(country);
          setPincode(Number(pincode));
          setState(state);
          setLatitude(latitude);
          setLongitude(longitude);
        }
      });
      setShowDropdown(true);
    }
    setShowDropdown(false);
  };
  const saveAddress = () => {
    let addressPayload = {
      addressLine1: address1,
      addressLine2: address2,
      isReachableByAmbulance: ambulanceAvailable,
      IsLiftAvailable: liftAvailable,
      city: city,
      landmark: landmark || "",
      pinCode: Number(pincode),
      state: state,
      label: addressLabel,
      country: country,
      location: [{ latitude: Number(latitude), longitude: Number(longitude) }],
      _id: generateUniqueID(),
    };
    if (typeof ambulanceAvailable !== "boolean" || typeof liftAvailable !== "boolean" || !address1) {
      return alert("Please Select the Ambulance & lift field || enter the AddressLine 1");
    }

    // if (openAddressModal) {
    //     setNearByAddress(addressPayload);
    // }
    // console.log("save:", addressPayload);
    setAddressData([...addressData, addressPayload]);
    // const addressText = ` ${addressPayload.addressLine2 || ""},${addressPayload.landmark}, ${addressPayload.addressLine1}, ${addressPayload.city}, ${addressPayload.state}, ${addressPayload.pinCode}`;
    // console.log('@@@@@addressText:@@@@@@@@@@@@@@@@@@@', addressText);
    setSelectedAddress(addressPayload);
    setaPatientCurrentLocation(addressPayload)
    // getGeolocation(addressText, addressPayload);

    setAddress1("");
    setAddress2("");
    setCity("");
    setLandmark("");
    setState("");
    setPincode(undefined);
    setCountry("");
    onClose();
    setAmbulanceAvailable(false);
    setLiftAvailable(false);

  };
  const editAddress = () => {
    // console.log('addressData:11', addressData);
    // console.log('currentAddressId:', currentAddressId);
    let addressPayload = {
      addressLine1: address1,
      addressLine2: address2,
      isReachableByAmbulance: ambulanceAvailable,
      city: city,
      landmark: landmark || "",
      isLiftAvailable: liftAvailable,
      pinCode: Number(pincode),
      state: state,
      label: addressLabel,
      country: country,
      currentLocation: isCurrentLocation,
      location: [{ latitude: Number(latitude), longitude: Number(longitude) }], //here prev lon first now changed to lat
      _id: currentAddressId !== undefined ? addressData[currentAddressId]._id : "Invalid",
    };
    let newAddressState = [];
    if (typeof ambulanceAvailable !== "boolean" || typeof liftAvailable !== "boolean" || !address1) {
      return alert("Please Select the Ambulance & lift field || enter the AddressLine 1");

    }
    // console.log('edit:', addressPayload)
    setaPatientCurrentLocation(addressPayload);
    for (let i = 0; i < addressData.length; i++) {
      if (i !== currentAddressId) {
        newAddressState.push(addressData[i]);
      } else {
        newAddressState.push(addressPayload);
      }
    }

    let addresses = newAddressState;
    // console.log('addresses:11', addresses);

    // setNearByAddress(addresses);

    setAddressData(addresses);
    setAddress2("");
    setCity("");
    setLandmark("");
    setState("");
    setPincode(undefined);
    setCountry("");
    setAmbulanceAvailable(false);
    setLiftAvailable(false);
    onClose();
    onEditClose();
    setSelectedAddress(addressPayload);
    //eslint-disable-next-line
    const addressText = `${address2},${landmark}, ${address1}, ${city}, ${state}, ${pincode}`;

    // getGeolocation(addressText, addressPayload);
  };
  const setaPatientCurrentLocation = (address: Address) => {
    getPreferredHospital(address);
    getPatientAddress(address);
  }


  return (
    <>
      {ticketData.currentMedicalStatusResponse.isAmbulanceRequired === "Yes" ||
        ticketData.currentMedicalStatusResponse.isDoctorConsultationRequired ===
        "Yes" ? (
        <Box>
          <Stack
            mt={"1rem"}
            className={style.card}
            direction={"column"}
            justify={"flex-start"}
          >
            <Box>
              <Text className={style.subTitle}>Step : Pickup location</Text>
            </Box>

            <Box>
              <Alert
                className={style.prompt_style}
                status="success"
                variant="left-accent"
                mb={".5rem"}
              >
                Please confirm the pickup location for the ambulance?
              </Alert>
            </Box>

            <Box>
              <Button colorScheme="green" onClick={handleModalOpen}>Enter New Pickup Location</Button>
            </Box>

            <Box className={style.address_grid}>
              {addressData?.length > 0 &&
                addressData?.map((el: any, i) => {
                  const isSelected = selectedAddress === el;

                  // const isCurrentLocation = el._id === nearByAddress?._id;

                  return (
                    <Box>
                      <Card bg={"#F5F9F9"}>
                        {el.currentLocation ? (
                          <Badge
                            mb={"-1rem"}
                            ml="1"
                            fontSize="0.8em"
                            colorScheme="green"
                          >
                            Current Location
                          </Badge>
                        ) : null}
                        <CardBody>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Box>
                              <Text>
                                Door No/Flat No:
                                {el.addressLine2 ? el.addressLine2 : ""}
                              </Text>
                              <Text fontWeight={600}>{el.addressLine1}</Text>
                              <Text>
                                {el.city} {el.pinCode || ""}
                              </Text>
                            </Box>

                            <Box className={style.circle}>
                              <Checkbox
                                onChange={() => handleSelectAddress(el, i)}
                                isChecked={isSelected}
                              ></Checkbox>
                            </Box>
                          </Box>
                        </CardBody>
                      </Card>
                    </Box>
                  );
                })}
            </Box>
          </Stack>
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={handleCancel}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Address</ModalHeader>
              <ModalCloseButton onClick={handleCancel} />
              <ModalBody pb={6}>
                <Input
                  ref={inputRef || ""}
                  value={address1 ? address1 : ""}
                  onChange={handleInputChange}
                  marginBottom={"10px"}
                  placeholder="Enter Location"
                  size="md"
                  // onFocus={handleInputFocus}
                />
                {showDropdown && (
                  <Box>
                    {autocompleteSuggestions.map((suggestion: any) => (
                      <Box
                        key={suggestion.place_id}
                        onClick={() => handleSuggestionClick(suggestion)}
                        p={2}
                        cursor="pointer"
                        _hover={{ bg: "gray.100" }}
                      >
                        {suggestion.description}
                      </Box>
                    ))}
                  </Box>
                )}
                <Input
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  marginBottom={"10px"}
                  placeholder="Flat No/Door No"
                  size="md"
                />
                <Input
                  value={landmark}
                  onChange={(e) => setLandmark(e.target.value)}
                  marginBottom={"10px"}
                  placeholder="Landmark (Optional)"
                  size="md"
                />
                <Text mb={".5rem"}>Is Ambulance Reachable</Text>
                <Select
                  value={ambulanceAvailable === false ? "No" : "Yes"}
                  onChange={(e) =>
                    setAmbulanceAvailable(
                      e.target.value === "Yes" ? true : false
                    )
                  }
                  marginBottom={"10px"}
                  size={"md"}
                  placeholder="Ambulance Reachable"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
                <Text mb={".5rem"}>Is Lift Available</Text>
                <Select
                  value={liftAvailable === false ? "No" : "Yes"}
                  onChange={(e) =>
                    setLiftAvailable(e.target.value === "Yes" ? true : false)
                  }
                  marginBottom={"10px"}
                  size={"md"}
                  placeholder="Lift Available"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
              </ModalBody>

              <ModalFooter>
                <Button onClick={saveAddress} colorScheme="blue" mr={3}>
                  Save Changes
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/* editModal _onlyCurrentAddress*/}
          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isEditOpen}
            onClose={handleCancel}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Update Address</ModalHeader>
              <ModalCloseButton onClick={handleCancel} />
              <ModalBody pb={6}>
                {/* <Input
                  value={address1 ? address1 : ""}
                  onChange={(e) => setAddress1(e.target.value)}
                  marginBottom={"10px"}
                  placeholder="Enter Location"
                  size="md"
                /> */}
                <Input
                  ref={inputRef || ""}
                  value={address1 ? address1 : ""}
                  onChange={handleInputChange}
                  marginBottom={"10px"}
                  placeholder="Enter Location"
                  size="md"
                  // onFocus={handleInputFocus}
                />
                {showDropdown && (
                  <Box>
                    {autocompleteSuggestions.map((suggestion: any) => (
                      <Box
                        key={suggestion.place_id}
                        onClick={() => handleSuggestionClick(suggestion)}
                        p={2}
                        cursor="pointer"
                        _hover={{ bg: "gray.100" }}
                      >
                        {suggestion.description}
                      </Box>
                    ))}
                  </Box>
                )}
                <Input
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  marginBottom={"10px"}
                  placeholder="Flat No/Door No"
                  size="md"
                />
                <Input
                  value={landmark}
                  onChange={(e) => setLandmark(e.target.value)}
                  marginBottom={"10px"}
                  placeholder="Landmark (Optional)"
                  size="md"
                />
                <Text mb={".5rem"}>Is Ambulance Reachable</Text>
                <Select
                  value={ambulanceAvailable === false ? "No" : "Yes"}
                  onChange={(e) => setAmbulanceAvailable(e.target.value === "Yes" ? true : false)}
                  marginBottom={"10px"}
                  size={"md"}
                  placeholder="Ambulance Reachable"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
                <Text mb={".5rem"}>Is Lift Available</Text>
                <Select
                  value={liftAvailable === false ? "No" : "Yes"}
                  onChange={(e) => setLiftAvailable(e.target.value === "Yes" ? true : false)}
                  marginBottom={"10px"}
                  size={"md"}
                  placeholder="Lift Available"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>


              </ModalBody>

              <ModalFooter>
                <Button onClick={editAddress} colorScheme="blue" mr={3}>
                  Save Changes
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      ) : null}
    </>
  );
};

export default PickupLocation;
