import React, { ReactElement} from "react";

interface MyComponentProps {
  children: ReactElement;
}
const PrivateRoute: React.FC<MyComponentProps> = ({ children }) => {
  // console.log(cookies,'accessToken ********************************* ');
  // const navigate = useNavigate();
  // // useEffect(() => {
  // //   const checkToken = async () => {
  // //     // navigate("/login");
  // //     try {
  // //       const data =  await axios.post(
  // //         `http://localhost:3002/api/v1/auth/refresh-token`,
  // //         {},
  // //         {
  // //           withCredentials: true, // Ensure credentials are sent with the refresh token request
  // //         }
  // //       );
  // //       console.log(data, "************verifiedToken***********");
  // //     } catch (err) {
  // //       // console.error(err);
  // //       navigate("/login");
  // //     }
  // //   };
  // //   checkToken(); // Call the async function
  // // }, [navigate]); // Run the effect when navigate function changes
  // let isLogin = localStorage.getItem("isLogin");
  // console.log(isLogin,'isLogin');
  // if(isLogin=="false"){
  //   navigate('/login');
  // }
  return children;
};

export default PrivateRoute;
