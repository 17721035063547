import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  HStack,
  Button,
  Select,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  useDisclosure,
  IconButton,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import SideBar from "../../GlobalComponents/Sidebar/SideBar";
import { getTicketData } from "./Action/getTciketData";
import { DateRangePicker } from "react-date-range";
import styleTicket from "./Ticket.module.css";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const Ticket: React.FC = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState<any>();
  const [status, setStatus] = useState("");
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const [agent, setAgent] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(10);
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
// console.log('  data?.count:',   data?.count);
  const agents:{ [key: string]: string } = {
    Amitav: "amitav.a@pococare.com",
    Bharath: "bharathkumar.n@pococare.com",
    Mahesh: "mahesh.r@pococare.com",
    Mounika: "mounika.s@pococare.com",
    Pradip: "pradip.c@pococare.com",
    Sanket: "sanket.j@pococare.com",
    Ranga: "ranga@pococare.com",
    // Vedic: "vedic.c@pococare.com",
  };
  const getTicket = async (to: string, from: string, status: string, pageSize: number, pageNumber: number, agent: string)=>{
    let url="";
    if(to!==""){
      url+=`to=${to}&`
    }
    if (from !== "") {
      url += `from=${from}&`;
    }
    if (status !== "") {
      url += `ticketStatus=${status}&`;
    }
    if (pageSize) {
      url += `pageSize=${pageSize}&`;
    }
    if (pageNumber) {
      url += `pageNumber=${pageNumber}&`;
    }
    if (agent) {
      // Make sure to use encodeURIComponent to properly encode the agent value
      url += `agent=${encodeURIComponent(agents[agent])}&`;
    }
    // Remove the trailing '&' if it exists
    if (url.endsWith("&")) {
      url = url.slice(0, -1);
    }
    try {
      setIsLoading(true);
      const ticketData = await getTicketData(url,navigate);
      setIsLoading(false);
      // console.log("ticketData:", ticketData);
      setData(ticketData);
    } catch (e:any) {
      setData([]);
      setIsLoading(false);
      // if (e.response.data.message ==="Unauthorized , Please login first"){
      //   alert(e?.response?.data?.message||"Some Technical Issue in Getting Tickets");
      //   localStorage.setItem('isLogin', "false");
      //   return navigate("/");
        
      // }else{
      //   return alert(e?.response?.data?.message || "Some Technical Issue in Getting Tickets");
      // }
      return alert(e?.response?.data?.message || "Some Technical Issue in Getting Tickets");

    }

  }
  const handleStatusChange = (statusData:string) => {
    setStatus(statusData);
    getTicket(
      to,
      from,
      statusData,
      pageSize,
      pageNumber,
      agent || ""
    );
  };
  const handleAgentChange = (value:string) => {
    setAgent(value);
    getTicket(to, from, status, pageSize, pageNumber, value);
  };
  const handlePageSizeChange = (value: string) => {
    setPageSize(Number(value));
    setPageNumber(1);
    getTicket(to, from, status, Number(value), 1, agents[agent] || "");
  };
  const handleDataChange = (type: string) => {
    if (type === "increasePage") {
      setPageNumber(pageNumber + 1);
      getTicket(
        to,
        from,
        status,
        pageSize,
        pageNumber + 1,
        agent || ""
      );
    }

    if (type === "decreasePage") {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
        getTicket(
          to,
          from,
          status,
          pageSize,
          pageNumber - 1,
          agent || ""
        );
      }
    }
  };
  const handleSelectedDate = (item: any) => {
    // console.log([item.selection]);
    setDateState([item.selection]);
  };
  const formateDate = (type: string) => {
    const date =
      type === "from" ? dateState[0].startDate : dateState[0].endDate;
    const formateDate = moment(date).format("DD-MMM-YYYY");
    return formateDate; // Format the date as required
  };
  const filterByDate = () => {
    const startDate = formateDate("from");
    const endDate = formateDate("to");
      setTo(endDate);
    setFrom(startDate);
       getTicket(
      endDate,
      startDate,
      status,
      pageSize,
      pageNumber,
      agent || ""
    );
    onClose();
  };
  const handleClearFilter = () => {
    setPageNumber(1);
    setPageSize(10);
    setTo("");
    setFrom("");
    setStatus("");
    setAgent("");
    getTicket("", "", "", 10, 1, "");
  };
  useEffect(() => {
    getTicket("", "", "", 10, 1, "");
    //eslint-disable-next-line
    let isLogin = localStorage.getItem("isLogin");
    if (isLogin === "false") {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []); 
  return (
    <>
      <SideBar />
      <Box flex={1} p={4} bg="white">
        <div className={styleTicket.mainContainer}>
          <Stack p={"4"} borderRadius={"10px"} border={"1px solid #cecece"}>
            <HStack>
              <HStack>
                <Button onClick={onOpen} colorScheme="blue">
                  Filter by date
                </Button>
              </HStack>
              <HStack>
                <Select
                  value={status}
                  onChange={(e) => handleStatusChange(e.target.value)}
                >
                  <option value={""}>Select Status</option>
                  <option value="New">New </option>
                  <option value="Work in progress">Work in progress</option>
                  <option value="Completed">Completed</option>
                  <option value="Canceled">Canceled</option>
                </Select>
              </HStack>
              <HStack>
                <Select
                  value={agent}
                  onChange={(e) => handleAgentChange(e.target.value)}
                >
                  <option value={""}>Select Agent</option>
                  <option value="Amitav">Amitav </option>
                  <option value="Bharath">Bharath</option>
                  <option value="Mahesh">Mahesh</option>
                  <option value="Mounika">Mounika</option>
                  <option value="Pradip">Pradip</option>
                  <option value="Sanket">Sanket</option>
                  <option value="Ranga">Ranga</option>
                  <option value="Vedic">Vedic</option>
                </Select>
              </HStack>
              <HStack>
                <Button onClick={handleClearFilter}>Clear Filters</Button>
              </HStack>
            </HStack>
            <div className={styleTicket.tableContainer}>
              {isLoading ? (
                <>loading</>
              ) : (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th
                        position="sticky"
                        top={0}
                        bgColor="white"
                        zIndex="sticky"
                      >
                        Ticket Id
                      </Th>
                      <Th
                        position="sticky"
                        top={0}
                        bgColor="white"
                        zIndex="sticky"
                      >
                        Agent Id
                      </Th>
                      <Th
                        position="sticky"
                        top={0}
                        bgColor="white"
                        zIndex="sticky"
                      >
                        Date
                      </Th>
                      <Th
                        position="sticky"
                        top={0}
                        bgColor="white"
                        zIndex="sticky"
                      >
                        Status
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.data?.length>0 &&
                      data.data.map((el: any) => (
                        <Tr
                          onClick={() => {
                            navigate(`/handleEmergency/${el._id}`);
                          }}
                          style={{ cursor: "pointer" }}
                          key={el._id}
                        >
                          <Td>{el._id || "N/A"}</Td>
                          <Td>{el?.agent?.email || "N/A"}</Td>
                          <Td>
                            {new Date(el.createdAt).toLocaleString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </Td>
                          <Td>{el.ticketStatus}</Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              )}
            </div>

            <HStack marginBottom={"10px"} justifyContent={"flex-end"}>
              <HStack>
                <Select
                  value={pageSize}
                  onChange={(e) => handlePageSizeChange(e.target.value)}
                >
                  <option value="10">10 </option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </HStack>
              <HStack>
                <IconButton
                  colorScheme="blue"
                  aria-label="Search database"
                  isDisabled={Number(pageNumber) === 1 ? true : false}
                  onClick={() => handleDataChange("decreasePage")}
                  icon={<GrFormPrevious />}
                />
                <Text>
                  {pageNumber + "/" + Math.ceil((data?.count||10) / pageSize)}
                </Text>
                <IconButton
                  colorScheme="blue"
                  aria-label="Search database"
                  isDisabled={data?.hasNextPage ? false : true}
                  onClick={() => handleDataChange("increasePage")}
                  icon={<GrFormNext />}
                />
              </HStack>
            </HStack>
          </Stack>
        </div>
      </Box>
      {/* date picker modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxH="600px" maxW="950px">
          <ModalHeader>Select Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DateRangePicker
              onChange={(item: any) => handleSelectedDate(item)}
              // showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateState}
              direction="horizontal"
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button onClick={filterByDate} variant="ghost">
              Apply
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Ticket;
