import { getProfilePerk } from "../../../Http";

export const sendMedicalPerk = async (benId: string) => {
  try {
    const response = await getProfilePerk(benId);
    return response.data.data;
  } catch (e: any) {
    throw e;
  }
};
