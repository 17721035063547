import { Box, Flex, Text } from "@chakra-ui/react"
import React from "react"

interface ServiceDownProps {
    ticketData: any
}
const ServiceDown: React.FC<ServiceDownProps> = ({ ticketData }) => {
    const getFileds = (errorService: any) => {
        const fields = Object.keys(errorService)
        return fields;

    }

    return (
        <>
            <Box>
                {
                    ticketData.internalServiceError ? <>
                        <Box>
                            <Flex>
                                <Text> {new Date(ticketData.createdAt).toLocaleString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                })}</Text>
                                <Text>{getFileds(ticketData.internalServiceError)}</Text>
                            </Flex>

                        </Box>
                    </> : <>---</>
                }
            </Box>
        </>)

}

export default ServiceDown