import React, { useState } from "react";
import { TicketProps } from "../../../../Helper/TicketInterface";
import { Box, HStack, Stack, Text } from "@chakra-ui/layout";
import style from "./EmergencyManagement.module.css";
import { Input } from "@chakra-ui/input";
import { Button } from "@chakra-ui/button";
import { AiOutlineSend } from "react-icons/ai";
// @ts-ignore
import ReactFileReader from "react-file-reader";
import { sendWhatsappMessage } from "../../Action/sendWhatsAppMsg";
import { useParams } from "react-router";
import { uploadFile } from "../../Action/uploadfile";
import { ticketUpdateById } from "../../../../Http";
import { sendMedicalPerk } from "../../Action/doctorPerk";
interface EmergencyManagementProps {
  data: TicketProps;
  getLatestTicketData:any;
  companyEmContacts:any;
}
const EmergencyManagement: React.FC<EmergencyManagementProps> = ({ data,getLatestTicketData,companyEmContacts }) => {
  const [doctorShared, setDoctorShared] = useState(
    data.doctorInfo ? true : false
  );
  const [prescriptionDocUrl, setPrescriptionDocUrl] = useState(
    data?.doctorInfo?.clinicalNotes[0]?.url || ""
  );
  const [isBtnLoading, setBtnIsLoading] = useState(false);
  const [doctorName, setDoctorName] = useState(
    data?.doctorInfo?.fullName || ""
  );
  const [doctorMobile, setDoctorMobile] = useState(
    data?.doctorInfo?.mobile || ""
  );
  const [uploading, setUploading] = useState(false);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleViewCurrentDocument = () => {
    window.open(
      prescriptionDocUrl || data?.doctorInfo?.clinicalNotes[0]?.url,
      "_blank"
    );
  };
  const getEmergencyContact = (emList: any) => {
    // console.log("emList:", emList);
    const MessageToList = emList.map((el: any) => {
      return el.mobile || el.whatsappNumber;
    });
    return MessageToList.length ? MessageToList : [];
  };
  const handlePrescriptionSend = async () => {
    setBtnIsLoading(true);

    let emergencyContact = getEmergencyContact(
      data?.patient?.emergencyContacts || []
    );

    const WAPayloadBen = {
      template: "NOTIFY_EC_ON_DOCTOR_CONSULT",
      data: {
        to: [...emergencyContact,data.callerID || data.ticketRaisedBy?.mobile, data?.poc?.mobile,...companyEmContacts],
        beneficiary: data?.patient?.fullName || "N/A",
      },
    };

    let waMessagePayload = {
      template: "NOTIFY_PATIENT_ON_PRESCRIPTION",
      data: {
        to: [
          ...emergencyContact,
          data.callerID || data.ticketRaisedBy?.mobile,
          data?.poc?.mobile,
        ],
        url: prescriptionDocUrl,
      },
    };
    sendWhatsAppMessageToEm(WAPayloadBen);
    sendWhatsAppMessageToEm(waMessagePayload);
  };
  const handleUpdatePrescriptionUrl = async (prescriptionUrl: string) => {
    const value = { url: prescriptionUrl, type: "img", tags: ["check"] };
    const ticketData = {
      ...data,
      doctorInfo: {
        ...data.doctorInfo,
        clinicalNotes: [...data?.doctorInfo?.clinicalNotes, value],
      },
    };
    await updateTicket(id, ticketData, "");
  };
  const handlePrescriptionDoc = async (files: any) => {
 
    setUploading(true);
    setPrescriptionDocUrl("uploading");
    try {
      let res = await uploadFile(
        data?.ticketRaisedBy?.benId,
        files,
        "doctorPrescription"
      );
      setPrescriptionDocUrl(res);
      setUploading(false);
      await handleUpdatePrescriptionUrl(res);
    } catch (error) {
      console.error(error);
      setUploading(false);
      setPrescriptionDocUrl("");
      alert("Some Technical Error from uploading PDF");
    }
  };
  const doctorPerk = async (benId: string) => {
    try {
      const res = await sendMedicalPerk(benId);
      if(res.signedUrl){
        return res;       
      }else{
        alert(
          "Some Technical issue in getting Doctor Perk"
        );
      }
     
    } catch (e: any) {
      console.log("error from ",e);
      alert(
        e?.response?.data?.message ||
          "Some Technical issue in getting Doctor Perk"
      );
      setBtnIsLoading(false);
    }
  };
  const handleProfileShare = async () => {
    if (!doctorName || !doctorMobile) {
      return alert("Please provide doctor name and mobile");
    }
    setIsLoading(true);

    let ticketUpdatePayload = {
      ...data,
      doctorInfo: {
        fullName: doctorName,
        mobile: doctorMobile,
      },
    };
    ////Doctor Perk/////
      let getDoctorUrl ;
    if(data?.ticketRaisedBy?.benId){
     getDoctorUrl = await doctorPerk(data?.ticketRaisedBy?.benId);
    }

    const whatsappMessagePayload = {
      template: "NOTIFY_DOCTOR_ABOUT_PATIENT",
      data: {
        to: [doctorMobile],
        doctorName: doctorName,
        beneficiary: data?.ticketRaisedBy?.fullName || "N/A",
        patient: data?.patient?.fullName, //from
        beneficiaryAge: data?.patient?.age || "N/A",
        beneficiarySex: data?.patient?.sex || "N/A",
        isBeneficiaryConcious:
          data?.currentMedicalStatusResponse?.isPatientConscious,
        patientCondition:
          data?.currentMedicalStatusResponse?.currentCondition?.join(",")||"N/A", //fromData
        isAmbulanceRequested:
          data?.currentMedicalStatusResponse?.isAmbulanceRequired, //fromData
        poc: data?.poc?.fullName, //fromData
        pocNumber: data?.poc?.mobile, //fromData
          url:getDoctorUrl?.signedUrl?.split('?')[0]||"https://www.e-verify.gov/sites/default/files/everify/guides/InstructionsCreatePDFofE-VerifyManual.pdf",
      },
    };
    try {
      if (id) {
        const response = await updateTicket(
          id,
          ticketUpdatePayload,
          whatsappMessagePayload
        );
        if (response) {
          await getLatestTicketData();
          setDoctorShared(true);
        
        }
      }
    } catch (e) {
      setIsLoading(false);
      return alert("Some Technical Issue Updating ticket data");
    }
    // ShowAlert("success", "Patient details shared to doctor");
  };
  const sendWhatsAppMessageToEm = async (payload: any) => {
    try {
      const response = await sendWhatsappMessage(payload);
      if (response) {
        setIsLoading(false);
        setBtnIsLoading(false);
        // alert("Patient details shared to doctor")
      }
    } catch (e: any) {
      setIsLoading(false);
      setBtnIsLoading(false);
      alert(e?.response?.data?.message || "Some Technical Issue sending whatsapp msg");
    }
  };
  const updateTicket = async (
    id: any,
    ticketPayload: any,
    waMessagePayload: any
  ) => {
    try {
      if (id) {
        const response = await ticketUpdateById(id, ticketPayload);
        if (response) {
        
          if (waMessagePayload !== "") {
            sendWhatsAppMessageToEm(waMessagePayload);
          }
         
          // alert("Emergency Resolved")
          return response;
        }
      }
    } catch (e:any) {
      console.error("Some Technical Issue sending whatsapp msg");
      setIsLoading(false);
      return alert(e?.response?.data?.message || "Some Technical Issue sending whatsapp msg");
    }
  };

  return (
    <>
      <Box className={style.wrapper}>
        <Stack
          mt={"1rem"}
          className={style.card}
          direction={"column"}
          justify={"flex-start"}
        >
          <Box>
            <Text textAlign={"center"} className={style.subTitle}>
              Emergency Management
            </Text>
          </Box>
          <Box className={style.gridSystem}>
            {doctorShared ? (
              <HStack
                className={style.card}
                p={10}
                justifyContent={"space-between"}
              >
                <Box>
                  <Text color={"#2f2e2e"} fontWeight={"500"}>
                    <Text color={"black"} as="span">
                      {" "}
                      Doctor Name:{" "}
                    </Text>{" "}
                    {doctorName || data?.doctorInfo?.fullName || ""}
                  </Text>
                  <Text color={"#2f2e2e"} fontWeight={"500"}>
                    <Text color={"black"} as="span">
                      {" "}
                      Contact:{" "}
                    </Text>{" "}
                    {doctorMobile || data?.doctorInfo?.mobile || ""}
                  </Text>
                </Box>
                <Box textAlign={"center"}>
                  {prescriptionDocUrl ||
                  data?.doctorInfo?.clinicalNotes[0]?.url ? (
                    <>
                      <Button
                        isLoading={uploading ? true : false}
                        loadingText="Uploading"
                        colorScheme="teal"
                        variant="outline"
                        marginTop={"10px"}
                        onClick={handleViewCurrentDocument}
                      >
                        View Document
                      </Button>
                      <Button
                        rightIcon={<AiOutlineSend />}
                        marginLeft={"10px"}
                        //   isDisabled={
                        //       data?.actions?.isDoctorPrescriptionShared === "Yes"
                        //           ? true
                        //           : false
                        //   }
                        isLoading={isBtnLoading} // need to make dynamic
                        colorScheme="teal"
                        variant="outline"
                        marginTop={"10px"}
                        onClick={handlePrescriptionSend}
                      >
                        Send
                      </Button>
                    </>
                  ) : (
                    <ReactFileReader
                      fileTypes={[".pdf"]}
                      base64={true}
                      multipleFiles={false}
                      handleFiles={handlePrescriptionDoc}
                    >
                      <Button marginTop={"10px"}> Upload Document</Button>
                    </ReactFileReader>
                  )}
                </Box>
              </HStack>
            ) : (
              <Box className={style.card}>
                <Text className={style.subTitle}>Doctor consultation</Text>
                <Box mt={"1rem"} className={style.flex}>
                  <Box w={"40%"}>
                    <Input
                      value={doctorName}
                      onChange={(e) => setDoctorName(e.target.value)}
                      placeholder={"Doctor Name"}
                    />
                  </Box>
                  <Box w={"40%"}>
                    <Input
                      value={doctorMobile}
                      onChange={(e) => {
                        const inputMobile = e.target.value;
                        // Remove any non-digit characters from the input
                        const cleanedMobile = inputMobile.replace(/\D/g, "");

                        // Ensure that the cleanedMobile doesn't exceed ten digits
                        if (cleanedMobile.length <= 10) {
                          setDoctorMobile(cleanedMobile);
                        }
                      }}
                      placeholder={"Mobile Number"}
                    />
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Button
                    isDisabled={data?.ticketStatus === "New" ? true : false}
                    onClick={handleProfileShare}
                    isLoading={isLoading}
                    mt={"1rem"}
                    colorScheme="green"
                  >
                    Share Patient Profile
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default EmergencyManagement;
