import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../Pages/DashBoard/Dashboard";
import Ticket from "../Pages/Ticket/Ticket";
import AgentLogin from "../Pages/AgentLogin/AgentLogin";
import PrivateRoute from "./PrivateRoutes/PrivateRoute";
import ProfilePerk from '../Pages/ProfilePerk/ProfilePerk';
import MedicalPerk from "../Pages/MedicalPerk/MedicalPerk";

const MainRoute: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/handleEmergency/:id" element={<Dashboard />} />
        <Route path='profile-perk/:id' element={<ProfilePerk/>}/>
        <Route path='doc-perk/:id' element={<MedicalPerk/>}/>
        <Route path="/" element={<AgentLogin />} />
        <Route
          path="/ticket"
          element={
            <PrivateRoute>
              <Ticket />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default MainRoute;
