import { Alert } from '@chakra-ui/alert'
import { Stack } from '@chakra-ui/layout'
import React, { useState } from 'react'
import style from "./PointOfContact/PointOfContact.module.css"
import { Button } from '@chakra-ui/button'
import { useParams } from 'react-router'
import { TicketUpdateInterface } from '../../../Helper/TicketInterface'
import { ticketUpdateById } from '../../../Http'
interface TicketCancelProps {
    ticketData: TicketUpdateInterface
    ticketUpdate:()=>void
}

const TicketCancel: React.FC<TicketCancelProps> = ({ ticketData,ticketUpdate }) => {
    const [loading, setLoading] = useState(false);
    const { id } = useParams()
    // console.log('id:', id)
    const handleCloseTicket = async () => {
        setLoading(true)
        if (id) {
            const payload = { ...ticketData, ticketStatus: "Cancelled" }
            try {
                const cancelTicket = await ticketUpdateById(id, payload);
                if (cancelTicket) {
                    setLoading(false)
                    ticketUpdate()
                }
            } catch (e:any) {
                alert(e?.response?.data?.message||"Some technical issue in updating ticketData")
                setLoading(false)
            }
        }

    };
    return (
        <>
            <Stack direction={"column"} align={"center"} justify={"center"}>
                <Alert
                    mt={"1rem"}
                    className={style.prompt_style_ans}
                    status="info"
                    variant="left-accent"
                >
                    I am sorry, but Pococare services are for medical emergencies only.
                    We provide ambulance and virtual doctor consultation for such cases.
                    If you do not require any of the above service I will close this
                    ticket
                </Alert>
                <Button isLoading={loading} onClick={handleCloseTicket} colorScheme="green">
                    Emergency Resolve
                </Button>
            </Stack>

        </>
    )
}

export default TicketCancel
