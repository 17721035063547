import { getAllBeneficiaryBySubId } from "../../../Http";
//need to refactor this api call///
export const getBeneficiaryData = async (subId: string) => {
  try {
    const { data } = await getAllBeneficiaryBySubId(subId);
    // console.log("data:", data);
    if (data.data.length) {
      return data.data;
    }
  } catch (e: any) {
    throw e;
    // return alert(e?.response?.data?.message||"Some Technical Issue")
    // return e.response?.data;
  }
};
