//eslint-disable-next-line
import React from 'react'

import style from "./PersonalDetails.module.css"
import { Box, Text } from '@chakra-ui/react'
import NameCard from '../NameCard'
import { TicketProps } from '../../../../Helper/TicketInterface'
import { useParams } from 'react-router-dom'
interface ticketProps{
    ticketData: TicketProps,
    pocName:string,
    pocNumber:string,
    patientName:any,
    patientNumber:string
}

const PersonalDetails: React.FC<ticketProps> = ({ ticketData, pocName, pocNumber, patientName, patientNumber }) => {
    // console.log('ticketData:', ticketData)
    const params = useParams<{ id: string }>();

    const { ticketRaisedBy,subscriber}=ticketData;
    // console.log('ticketData:', ticketData)
    // console.log('patientNumber:', patientNumber)
 
    return (    
        <>
            <Box className={style.wrapper}>
                <Box className={style.container}>
                    <Box>
                        <Text textAlign={"center"} className={style.heading_style}>
                            PersonalDetails-TicketNo-{params.id}
                        </Text>
                        <Box className={style.gridSystem}>
                            <NameCard
                                title={"Subscriber"}
                                name={subscriber?.fullName || "Not Found"}
                                mobileNumber={subscriber?.mobile || "Not Found"}
                                company={ticketData?.companyName|| "Company N/F"}
                            />
                            <NameCard
                                title={"Beneficiary"}
                                name={ticketRaisedBy?.fullName || "Not Found"}
                                mobileNumber={ticketRaisedBy?.mobile || "Not Found"}
                                company={""}
                            />
                            {/* //patient details it should be async once patient agent selected from this cad also that detail need to update */}
                            <NameCard
                                title={"Patient"}
                                name={ticketData?.patient?.fullName||patientName}
                                mobileNumber={ticketData?.patient?.mobile||patientNumber}
                                company={""}
                            /> 
                            
                            {/* //once Poc is updated that time it need to update the Poc Details Dynamically here */}
                            <NameCard
                                title={"POC"}
                                name={ticketData?.poc?.fullName||pocName ||"Poc Not Selected"}
                                mobileNumber={ticketData?.poc?.mobile||pocNumber ||"Poc Not Selected"}
                                company={""}
                            /> 
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default PersonalDetails
