import axios from "axios";

export const integrateAmbulance = async (sLat: number, sLong: number, dLat: number, dLong: number, sAddress: string, dAddress: string) => {
    
    let data = `created_by=4&latitude=${sLat}&longitude=${sLong}&d_latitude=${dLat}&d_longitude=${dLong}&timezone=Asia%2FKolkata&city_id=647f049db9860a3d8443bc6d&payment_id=0&is_ride_later=0&start_time=&country=India&country_phone_code=%2B91&payment_mode=1&is_surge_hours=0&surge_multiplier=1&user_type=2&user_type_id=64101c4c2ccde4bd7b71ce59&trip_type=3&device=web&token=RDspwjM8GVRf9dWAqGAcWrqgXjKD5ipz&city=&user_id=6409b00d078cd5ab18ab93b5&service_type_id1=&source_address=${sAddress}&destination_address=${dAddress}&trip_type_option=1&service_type_id=647f136eb9860a3d8443c468&select_user=6409b00d078cd5ab18ab93b5&first_name=Test&last_name=User&email=test%40gmail.com&phone=1234567890&provider_type=1&provider_full_name=&provider_phone=&car_rental_id=&select_trip_provider=`;

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.pococare.com/createtrip',
        data: data
    };
    try {
        let response = await axios.request(config);
        localStorage.setItem("tripId", response.data.trip_id)
        return response.data
    } catch (error:any) {
        console.log(error)
    }

}