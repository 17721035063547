import * as react from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import hospitalStyle from "./PreferredHospital.module.css";
import { useNavigate } from "react-router-dom";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { FaRegHospital } from "react-icons/fa";
import { BenDataProps } from "../../../../Helper/BeneInterface";

const ProfilePreferredHospital:React.FC<{data:BenDataProps}>= ({data}) => {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const checksAvailable = () => {
    const hasKey = data.address.some((el) =>
      el.hasOwnProperty("preferredHospital")
    );
    setShow(hasKey);
  };
  const handleEdit = () => {
    navigate(`/addBenFullDetails/${data._id}/6`);
  };

  // here i removed empty dependencies from useEffect if any re-render happen need to change this
  useEffect(() => {
    checksAvailable();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      {data.address.length > 0 ? (
        <react.Box className={hospitalStyle.hospital_wrapper}>
          <react.Box className={hospitalStyle.hospital_container}>
            <react.Box>
              <react.Text className={hospitalStyle.subTitle}>
                Preferred Hospital's
              </react.Text>
            </react.Box>
            {!show ? (
              <react.Box>
                <react.Text>
                  No Proffered hospital
                </react.Text>
              </react.Box>
            ) : (
              data?.address.map((el,i) => {
            
                return (
                  el.preferredHospital && (
                    <react.Box className={hospitalStyle.hospitals_details} key={i}>
                      <react.Box className={hospitalStyle.left}>
                        <react.Flex align={"center"} gap={"10px"}>
                          <react.Box>
                            <react.Image src="/Images/home.svg" />
                          </react.Box>
                          <react.Box>
                            <react.Text
                              className={hospitalStyle.hospitalAddress}
                            >
                              Home
                            </react.Text>
                          </react.Box>
                        </react.Flex>
                        <react.Box>
                          <react.Text className={hospitalStyle.typeHospital}>
                            {el.addressLine1 || ""}
                          </react.Text>
                        </react.Box>
                        <react.Box>
                          <react.Text>{el.addressLine2 || ""}</react.Text>
                        </react.Box>
                        <react.Box>
                          <react.Text>
                            {el.city || ""} {el.pinCode || ""}
                          </react.Text>
                        </react.Box>
                      </react.Box>
                      
                      {
                        el.preferredHospital.map((hospital)=>{
                          return(
                            <react.Box className={hospitalStyle.left}>
                              <react.Flex align={"center"} gap={"10px"}>
                                <react.Box>
                                  <FaRegHospital />
                                </react.Box>
                                <react.Box>
                                  {/* <TextLimit
                                    // className={hospitalStyle.hospitalAddress}
                                    text={hospital?.hospitalName || "Hospital Not Found Add"}
                                    maxLength={20}
                                  ></TextLimit> */}
                                </react.Box>
                              </react.Flex>
                              <react.Box>
                                <react.Text className={hospitalStyle.typeHospital}>
                                  {hospital.hospitalAddress || ""}
                                </react.Text>
                                <react.Text className={hospitalStyle.typeHospital}>
                                  Km:{hospital.distance || ""}
                                </react.Text>
                              </react.Box>
                              {/* <react.Box>
                        <react.Text>
                          {el?.preferredHospital?.hospitalMobile || ""}
                        </react.Text>
                      </react.Box> */}
                            </react.Box>
                          )

                        })
                      }
                      
                     
                    </react.Box>
                  )
                );
              })
            )}
          </react.Box>
          {show && (
            <react.Box className={hospitalStyle.editIcon}>
              <HiOutlinePencilSquare
                onClick={handleEdit}
                color={"#008470"}
                fontSize={"23px"}
              />
            </react.Box>
          )}
        </react.Box>
      ) : null}
    </>
  )
}

export default ProfilePreferredHospital
