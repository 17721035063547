import { getMedicalInfo } from "../../../Http"

export const getMedicalDetails = async (id:string) => {
    try{
        
        const {data} =  await getMedicalInfo(id);
        return data;

    }catch(error){
        throw error
    }
}