import { Box,  Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import style from "./CallSection.module.css";
import {
    EmergencyContact,
    TicketProps,
    TicketUpdateInterface,
} from "../../../../Helper/TicketInterface";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { callManuallyAction } from "../../Action/ozonetel";
import { useParams } from "react-router-dom";
import AgentNotes from "../AgentNotes/AgentNotes";
import ServiceDown from "../ServiceDown/ServiceDown";

interface CallProps {
    // ticketData: TicketUpdateInterface,
    ticketData: TicketUpdateInterface;
    agentData: TicketProps;
    hospitalContact: string;
    callLogsList: any;
    updateCallLogs: any
    ticketUpdate:()=>void;
}
const CallSection: React.FC<CallProps> = ({
    ticketData,
    agentData,
    hospitalContact,
    callLogsList,
    updateCallLogs,
    ticketUpdate
}) => {
    // console.log('ticketData:', ticketData)
    //eslint-disable-next-line
    const [emList, setEmList] = useState<EmergencyContact[]>(
        ticketData?.patient?.emergencyContacts ||
        agentData?.patient?.emergencyContacts ||
        []
    );
    const params = useParams();
    const handleSelectCallLog = async (agentId: string, customerNumber: any) => {
        // console.log(agentId, customerNumber);
        if (!agentId || !customerNumber) {
            alert("mobile Not Found Selected Ben");
            return;
        }
        try {
            const obj = {
                agentId,
                customerNumber,

            };
            if (params.id) {
                const response = await callManuallyAction(obj, params.id);
                if (response?.message) {
                    alert(response?.message || "Some Technical Issue in Call Connecting");
                    updateCallLogs()
                }
            }
        } catch (e: any) {
            alert(e?.response?.data?.message || "Some Technical Issue in Call Connecting")
            console.error(e)
        }
    };
    console.log('agentData:', agentData);
    
    return (
        <>
            <Box className={style.wrapper}>
                <Box className={style.container}>
                    {/* calls container */}
                    <Box>
                        <Box>
                            <Text className={style.heading_style}>Call</Text>
                        </Box>

                        <Stack
                            className={style.card}
                            mt={"1rem"}
                            direction={"column"}
                            justify={"flex-start"}
                        >
                            <Box>
                                {" "}
                                <Text className={style.subTitle}>Call Details</Text>
                            </Box>
                            <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                                <Text className={style.text_style}>Call from Phone:</Text>
                                <Text className={style.text_style_bold}>
                                    {agentData?.callerID}
                                </Text>
                            </Stack>
                            <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                                <Text>Caller Type:</Text>
                                <Text className={style.text_style_bold}>
                                    {" "}
                                    {agentData?.callerType}
                                </Text>
                            </Stack>
                            <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                                <Text>Source Of Call:</Text>
                                <Text className={style.text_style_bold}>
                                    {agentData.sourceOfTicket}
                                </Text>
                            </Stack>
                            <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                                <Text>Client Of:</Text>
                                <Text className={style.text_style_bold}>
                                    {agentData.companyName ? agentData.companyName : "Pococare"}
                                </Text>
                            </Stack>
                            <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                                <Text>CampusId:</Text>
                                <Text className={style.text_style_bold}>
                                    {agentData?.campusId ? agentData.campusId : "N/A"}
                                </Text>
                            </Stack>
                           {agentData?.detectedLocation?.address? <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                                <Text>Address:</Text>
                                <Text className={style.text_style_bold}>
                                    {agentData?.detectedLocation?.address ? agentData?.detectedLocation?.address : "N/A"}
                                </Text>
                            </Stack>:<></>}
                        </Stack>
                    </Box>
                    {/* calls-log container */}
                    <Box>
                        <Stack
                            className={style.card}
                            mt={"1rem"}
                            direction={"column"}
                            justify={"flex-start"}
                        >
                            <Box>
                                {" "}
                                <Text className={style.subTitle}>Call Logs</Text>
                            </Box>
                            {callLogsList &&
                                callLogsList?.map((el: any) => {
                                    return (
                                        <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                                            <Text className={style.text_style}>
                                                {el?.callerId && "beneficiary :"}
                                            </Text>
                                            <Text className={style.text_style_bold}>{el?.callerId && el?.callerId}</Text>
                                        </Stack>
                                    );
                                })}
                        </Stack>
                    </Box>
                    {/* calls-to container */}
                    <Box>
                        <Stack
                            className={style.card}
                            mt={"1rem"}
                            direction={"column"}
                            justify={"flex-start"}
                        >
                            <Box>
                                <Text className={style.subTitle}>Call to</Text>
                            </Box>
                            <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                                <BsFillTelephoneOutboundFill color="rgb(0, 132, 112)" />
                                <Text
                                    cursor={"pointer"}
                                    onClick={() => {
                                        handleSelectCallLog(
                                            agentData?.agent?.email || "",
                                            ticketData?.patient?.mobile || agentData?.patient?.mobile
                                        );
                                    }}
                                    className={style.text_style}
                                >
                                    {ticketData?.patient?.fullName ||
                                        agentData?.patient?.fullName}
                                </Text>
                            </Stack>
                            {emList.length > 0 &&
                                emList.map((el: EmergencyContact, i: any) => {
                                    return (
                                        <Stack
                                            key={i}
                                            direction={"row"}
                                            spacing={"1rem"}
                                            align={"center"}
                                        >
                                            <BsFillTelephoneOutboundFill color="rgb(0, 132, 112)" />
                                            <Text
                                                cursor={"pointer"}
                                                onClick={() => {
                                                    handleSelectCallLog(
                                                        agentData?.agent?.email || "",
                                                        el?.mobile || ""
                                                    );
                                                }}
                                                className={style.text_style}
                                            >
                                                {el.fullName}(EC)
                                            </Text>
                                        </Stack>
                                    );
                                })}

                            <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                                {/* <BsFillTelephoneOutboundFill color="rgb(0, 132, 112)" /> */}

                                <Text className={style.text_style}>
                                    Hospital contact Details:
                                    {/* {hospitalContact?.contactNumber1 ||
                                      getData?.hospitalSelected?.contactNumber1} */}
                                    {hospitalContact || agentData?.hospitalInfo?.contactNumber}
                                </Text>
                            </Stack>
                        </Stack>
                    </Box>

                    {/* serviceDown */}
                    <Stack
                            className={style.card}
                            mt={"1rem"}
                            direction={"column"}
                            justify={"flex-start"}
                        >
                            <Text className={style.subTitle}>Service-Down-Notification</Text>
                            <ServiceDown ticketData={agentData} />
                        </Stack>
                    {/* agentNotes */}
                  
                    <Box>
                        <Stack
                            className={style.card}
                            mt={"1rem"}
                            direction={"column"}
                            justify={"flex-start"}
                        >
                            <Text className={style.subTitle}>Agent Notes</Text>
                            <AgentNotes ticketUpdate={ticketUpdate} ticketData={agentData} />
                        </Stack>

                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CallSection;
