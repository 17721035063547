import { getSingleBenData } from "../../../Http";
import { getBeneficiaryData } from "./getAllBeneficiary";

export const getSingleBenDetails = async (benId: string) => {
  try {
    const { data } = await getSingleBenData(benId);
    
    return await getBeneficiaryData(data.subscriberId)
  } catch (e: any) {
    // console.error("error from getting single ben data");
    // return alert(e.response.data.message);
    throw e
  }
};
