import { callManually,  saveCallDetailsLogs } from "../../../Http"

export const callManuallyAction=async(payload:any,ticketId:string)=>{
    try{
        const response=await callManually(payload)
        // console.log('response:', response);
        await saveCallLogs(ticketId,response.data.data.ucid,payload.customerNumber)
        return response.data
    }catch(e:any){
        throw e;
    }
}

const saveCallLogs = async (ticketId: string, ucid: string,callerId:string) => {
  try {
  const formattedDateTime = new Date()
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
    const payload = { ticketId, ucid, callerId, formattedDateTime };
    // console.log('payload:', payload);
    await saveCallDetailsLogs(payload);
  } catch (e: any) {
    throw e;
  }
};