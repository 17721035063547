import { Address } from "../../../Helper/BeneInterface";

function calculateDistance(currentLocation: any, addresses: any) {
  return new Promise((resolve, reject) => {
    const service = new window.google.maps.DistanceMatrixService();

    service.getDistanceMatrix(
      {
        origins: [currentLocation],
        destinations: addresses,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
      },
      (response, status) => {
        // console.log("response:", response);
        if (status === "OK") {
          const distances = response.rows[0].elements.map((element) => {
            if (element.distance && element.distance.text) {
              return element.distance.value;
            } else {
              return null; // or handle the missing distance value as needed
            }
          });
          resolve(distances);
        } else {
          reject(
            console.error(`Distance Matrix request failed with status: ${status}`)
          );
        }
      }
    );
  });
}

export async function findClosestAddress(
  targetLatitude: any,
  targetLongitude: any,
  addresses: any
) {
  // console.log(
  //   "targetLatitude, targetLongitude, addresses:",
  //   targetLatitude,
  //   targetLongitude,
  //   addresses
  // );
  try {
    let closestAddress = null;
    let minDistance = Infinity;

    const distancePromises = addresses.map((address: Address) => {
      const lat = address.location.latitude.toString();
      const lng = address.location.longitude.toString();

      return calculateDistance({ lat: targetLatitude, lng: targetLongitude }, [
        { lat: parseFloat(lat), lng: parseFloat(lng) },
      ]);
    });

    const distances = await Promise.all(distancePromises);
    // console.log("distances:", distances);
    for (let i = 0; i < addresses.length; i++) {
      // console.log("distances[i]:", distances);
      if (
        distances[i][0] !== null &&
        distances[i][0] >= 0 &&
        distances[i][0] <= 200 &&
        distances[i][0] < minDistance
      ) {
        minDistance = distances[i];
        closestAddress = addresses[i];
      }
    }
    return closestAddress;
  } catch (e) {
    console.error("error from google calculate closest distance", e);

    return null;
  }
}
