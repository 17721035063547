import { Box, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import style from "./GlobalCss/Card.module.css"
interface CardProps{
title:string
name:string
mobileNumber:string
company:string
}
const NameCard: React.FC<CardProps> = ({ title, name, mobileNumber, company }) => {
  return (
      <Box>
          <Stack
              className={style.card}
              mt={"1rem"}
              direction={"column"}
              justify={"flex-start"}
          >
              <Box>
                  <Text className={style.subTitle}>{title}</Text>
              </Box>
              <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                  {/* <Text className={style.text_style}>Name:</Text> */}
                  <Text className={style.text_style_bold}>{name}</Text>
              </Stack>
              <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                  {/* <Text>Phone :</Text> */}
                  <Text className={style.text_style_bold}> {mobileNumber}</Text>
              </Stack>
              <Stack direction={"row"} spacing={"1rem"} align={"center"}>
                  {/* <Text>Phone :</Text> */}
                  <Text className={style.text_style_bold}> {company || ""}</Text>
              </Stack>
          </Stack>
      </Box>
  )
}

export default NameCard
