


// Import the Moment.js library

import moment from "moment";

export const changeDateFormate =  (dateOfBirth: string) => {

  const parsedDate = moment(dateOfBirth, "YYYY-DD-MM");

  if (parsedDate.isValid()) {
    // Check if the parsed date is valid
    // Format the date in the desired format (YYYY-MM-DD)
    const formattedDate = parsedDate.format("YYYY-MM-DD");
    return  calculateAge(formattedDate);
  } else {
    console.error("Invalid date format.");
  }
};



 const calculateAge =  (dateOfBirth: string) => {
  const today = new Date();
  const birthDateObj = new Date(dateOfBirth);
  // console.log("birthDateObj:", birthDateObj);
  let calculatedAge = today.getFullYear() - birthDateObj.getFullYear();
  const birthMonth = birthDateObj.getMonth() + 1;
  const currentMonth = today.getMonth() + 1;
  const monthDifference = currentMonth - birthMonth;

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    calculatedAge--;
  }

  let ageString = calculatedAge + " years";

  if (calculatedAge < 1) {
    if (monthDifference === 0) {
      ageString = monthDifference + " months";
    } else if (monthDifference < 0) {
      ageString = "Invalid month entered";
    } else {
      ageString = monthDifference + " months";
    }
  }

  return ageString;
};