import { Box, Button, Flex, Text, Textarea, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { updateTicket } from '../../Action/updateTicket';
import { useParams } from 'react-router-dom';
import { TicketProps } from '../../../../Helper/TicketInterface';
interface AgentNotesProps {
  ticketData: TicketProps
  ticketUpdate: any;
}
const AgentNotes: React.FC<AgentNotesProps> = ({ ticketUpdate, ticketData }) => {
  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false)
  const [notes, setNotes] = useState("");
  const handleCatch = (e: any) => {
    setNotes(e.target.value)
  }


  const updateAgentNotes = async () => {
    if (params.id) {
      setIsLoading(true)
      // console.log("", ticketData.notes);
      const currentDate = new Date();
      const isoDate = currentDate.toISOString();

      const payload = {
        notes: [...ticketData.notes, {
          date: isoDate,
          message: notes
        }]
      };
      try {
        await updateTicket(params.id, payload);
        setIsLoading(false)
        ticketUpdate();
        setNotes("")
      } catch (e: any) {
        setIsLoading(false)
        e?.response?.data?.message ? alert(e.response.data.message) : alert("Some Technical Issue in Update Ticket")

      }
    }
  };
  const handleSubmit = () => {
    updateAgentNotes()
  }
  return (
    <>
      <Box>
        {/* // need to seprate this card component  */}
        {ticketData.notes.length ? <Box>
          {
            ticketData.notes.length && ticketData.notes.map((el: any) => {
              return (
                <>
                  <Flex direction={"column"}>
                    <Text> Date:{new Date(el.date).toLocaleString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}</Text>
                    <Text>comment: {el.message}</Text>

                  </Flex>
                </>
              )
            })
          }
        </Box> : <></>}
        <VStack gap={"1rem"}>
          <Textarea value={notes} onChange={handleCatch}></Textarea>
          <Button colorScheme='green' isLoading={isLoading} onClick={handleSubmit}>Submit</Button>
        </VStack>
      </Box>
    </>
  )
}

export default AgentNotes
